import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-logout-prompt',
  template: `
    <h3>Log Out</h3>
    <p>Are you sure you want to logout?</p>
    <p>
      <button mat-flat-button color="primary" (click)="cancel()">No</button>
      <button mat-flat-button color="primary" (click)="confirm()">Yes</button>
    </p>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        max-width: 300px;
      }
    `
  ]
})
export class LogoutPromptComponent {
  constructor() {}

  cancel() {
    // this.ref.close(false);
  }

  confirm() {
    // this.ref.close(true);
  }
}
