import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ToastListComponent } from '@app/core/components/toast-list/toast-list.component';
import { ToastItemComponent } from '@app/core/components/toast-item/toast-item.component';

export const COMPONENTS = [
  ToastListComponent,
  ToastItemComponent
];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class ComponentsModule {}
