import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromCoreStore from '@app/core/store';
import * as fromAuthStore from '@app/auth/store';

import { AuthService } from '@app/auth/services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public auth: AuthService,
    private store: Store<fromCoreStore.State>
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // this.progress.start();
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`
      }
    });

    // Check Auth on Response
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          // this.progress.complete();
          if (event instanceof HttpResponse) {
            // console.log('[Auth] OK');
            // do stuff with response if you want
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // this.progress.complete();
            if (err.status === 401) {
              this.auth.collectFailedRequest(request);
              console.log('[Auth] Failed');
              this.store.dispatch(new fromAuthStore.LoginRequest());
            }
          }
        }
      )
    );
  }
}
