import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/core/store/reducers';
import * as fromPagination from '@app/core/store/reducers/pagination.reducers';
// Paginations State

export const selectPaginationState = createSelector(
  fromFeature.selectCoreState,
  (state: fromFeature.State) => state.pagination
);

export const getItemsCount = createSelector(
  selectPaginationState,
  fromPagination.getItemsCount
);
