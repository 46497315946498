import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/core/store/reducers';
import * as fromAvatar from '@app/core/store/reducers/avatar.reducers';

// Avatars State
export const selectAvatarState = createSelector(
  fromFeature.selectCoreState,
  (state: fromFeature.State) => state.avatars
);

export const getAvatarLoaded = createSelector(
  selectAvatarState,
  fromAvatar.getLoaded
);
export const getAvatarLoading = createSelector(
  selectAvatarState,
  fromAvatar.getLoading
);

export const selectAvatarIds = createSelector(
  selectAvatarState,
  fromAvatar.selectAvatarIds
);
export const selectAvatarEntities = createSelector(
  selectAvatarState,
  fromAvatar.selectAvatarEntities
);
export const selectAllAvatars = createSelector(
  selectAvatarState,
  fromAvatar.selectAllAvatar
);
export const selectAvatarTotal = createSelector(
  selectAvatarState,
  fromAvatar.selectAvatarTotal
);
