import { NgModule, ErrorHandler } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TokenInterceptor } from "@app/auth/services/token.interceptor";

import { environment } from "environments/environment";

import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import {
  StoreRouterConnectingModule,
  RouterStateSerializer
} from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";

import { QuillModule } from "ngx-quill";

import {
  reducers,
  effects,
  metaReducers,
  CustomSerializer
} from "@app/core/store";

import { AppComponent } from "@app/core/containers/app/app.component";
import { HeaderComponent } from "@app/core/containers/header/header.component";
import { FooterComponent } from "@app/core/containers/footer/footer.component";
import { NotFoundPageComponent } from "@app/core/containers/not-found-page";

import { ComponentsModule } from "@app/core/components";

import { MaterialModule } from "@app/material";
import { AuthModule } from "@app/auth/auth.module";
import { PipesModule } from "@app/shared/pipes";
import { MyCurrencyPipe } from "@app/shared/pipes/my-currency.pipe";
import { SharedModule } from "@app/shared/shared.module";
import { AppRoutingModule } from "@app/core/app-routing.module";

import { ApiService } from "@app/shared/services/api.service";

import { SentryErrorHandler } from "@app/core/services/sentry.service";

import {
  ToastHelper,
  FileService,
  ContactService,
  EquipmentService,
  MailNotifyService,
  OrderService
} from '@app/core/services';
import { LayoutModule } from '@angular/cdk/layout';

export const COMPONENTS = [
  AppComponent,
  HeaderComponent,
  FooterComponent,
  NotFoundPageComponent
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    AppRoutingModule,
    StoreModule.forRoot({}, { metaReducers }),
    StoreModule.forFeature("core", reducers),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    QuillModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    PipesModule,
    SharedModule,
    MaterialModule,
    AuthModule,
    LayoutModule
    // SocketIoModule.forRoot(config) ,
  ],
  declarations: COMPONENTS,
  providers: [
    ApiService,
    MyCurrencyPipe,
    ToastHelper,
    FileService,
    ContactService,
    EquipmentService,
    MailNotifyService,
    OrderService,
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
    // { provide: ErrorHandler, useClass: SentryErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
