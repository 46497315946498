import { Action } from '@ngrx/store';

export enum PaginationActionTypes {
  CHANGE_ITEMS_COUNT = '[Pagination] CHANGE_ITEMS_COUNT',
}

export class ChangeItemsCount implements Action {
  readonly type = PaginationActionTypes.CHANGE_ITEMS_COUNT;
  constructor(public payload: number) {}
}

export type PaginationActions =
  | ChangeItemsCount;
