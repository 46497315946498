import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Stop } from '@app/shared/models/stop.model';

@Component({
  selector: 'app-stop-modal',
  templateUrl: './stop-modal.component.html',
  styleUrls: ['./stop-modal.component.css']
})
export class StopModalComponent {
  stop: Stop;

  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<StopModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.stop = data;

    this.form = this.fb.group({
      streetNumber: new FormControl(this.stop.streetNumber),
      addressLine1: new FormControl(this.stop.addressLine1),
      city: new FormControl(this.stop.city, [Validators.required]),
      state: new FormControl(this.stop.state, [Validators.required]),
      date: new FormControl(this.toDateString(new Date(this.stop.date)), [Validators.required]),
    });
  }

  private toDateString(date: Date): string {
    return (date.getFullYear().toString() + '-'
       + ('0' + (date.getMonth() + 1)).slice(-2) + '-'
       + ('0' + (date.getDate())).slice(-2))
       + 'T' + date.toTimeString().slice(0, 5);
  }

  onSubmit() {
    if (this.form.invalid) {
      // this.notify.error('Please enter valid form inputs!');
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      return;
    }
    this.dialogRef.close({ ...this.stop, ...this.form.value });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
