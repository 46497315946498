import { Phone } from '@app/shared/models/phone.model';
import { Email } from '@app/shared/models/email.model';
import { Address } from '@app/shared/models/address.model';
import { File } from './file.model';
// import { History } from '@app/shared/models/history.model';

export interface Contact {
  _id?: string;
  avatarId?: string;
  avatar?: File;
  name?: string;
  title?: string;
  isAccount?: boolean;
  role?: string;
  contacts?: string[];
  emails?: Email[];
  phones?: Phone[];
  // addresses: Address[]; CRM US using multiple
  address?: Address;
  permissions?: [string];
  // history?: History[];
  website?: string;
  createdAt?: string;
  avatarImgId?: string;
}

export function genMockContact(): Contact {
  return {
    isAccount: false,
    name: ''
  };
}
