import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/core/store/reducers';
import * as fromFile from '@app/core/store/reducers/file.reducers';

// Files State
export const selectFileState = createSelector(
  fromFeature.selectCoreState,
  (state: fromFeature.State) => state.files
);

export const getFileLoaded = createSelector(
  selectFileState,
  fromFile.getLoaded
);
export const getFileLoading = createSelector(
  selectFileState,
  fromFile.getLoading
);

export const selectFileIds = createSelector(
  selectFileState,
  fromFile.selectFileIds
);
export const selectFileEntities = createSelector(
  selectFileState,
  fromFile.selectFileEntities
);
export const selectAllFiles = createSelector(
  selectFileState,
  fromFile.selectAllFile
);
export const selectFileTotal = createSelector(
  selectFileState,
  fromFile.selectFileTotal
);
export const selectCurrentFileId = createSelector(
  selectFileState,
  fromFile.getSelectedId
);

export const selectCurrentFile = createSelector(
  selectFileEntities,
  selectCurrentFileId,
  (entities, fileId) => entities[fileId]
);

export const selectFileById = _id =>
  createSelector(
    selectAllFiles,
    files => {
      return files.find(f => f._id === _id);
    }
  );
export const selectFilesByResource = (resource) =>
createSelector(
  selectAllFiles,
  files => {
    return files.filter(
      file => file.resource === resource
    );
  }
);

export const selectFilesByResourceAndResourceId = (resource, resourceId) =>
createSelector(
  selectAllFiles,
  files => {
    return files.filter(
      file => file.resource === resource && file.resourceId === resourceId
    );
  }
);

export const selectSelectedFiles = createSelector(
  selectAllFiles,
  (files) => files.filter(file => file._selected)
);

export const selectFilesByResourceSelected = (resource, resourceId) =>
  createSelector(
    selectAllFiles,
    files => {
      return files.filter(
        file =>
          file._selected
          && file.resource === resource
          && file.resourceId === resourceId
      );
    }
  );
