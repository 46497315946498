import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@app/material';

import { QuillModule } from 'ngx-quill';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import {
  DropzoneComponent,
  FilesComponent,
  FileListComponent,
  BtnConfirmComponent,
  ContactSearchComponent,
  ContactCardComponent,
  OrderTableComponent
} from '@app/shared/components';

import {
  ContactDialogComponent,
  StopModalComponent,
  FilePreviewDialogComponent,
  EquipmentModalComponent,
  EmailSendModalComponent
} from '@app/shared/popups';

import {
  CanAccessDirective,
  GooglePlaceAutoCompleteDirective
} from '@app/shared/directives';

const COMPONENTS = [
  DropzoneComponent,
  FilesComponent,
  FileListComponent,
  BtnConfirmComponent,
  ContactSearchComponent,
  ContactCardComponent,
  OrderTableComponent
];

const POPUPS = [
  ContactDialogComponent,
  StopModalComponent,
  EquipmentModalComponent,
  EmailSendModalComponent,
  FilePreviewDialogComponent
];

const DIRECTIVES = [
  CanAccessDirective,
  GooglePlaceAutoCompleteDirective
];
@NgModule({
  imports: [
    QuillModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
    AngularMultiSelectModule,
  ],
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...POPUPS
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...POPUPS,
  ],
  entryComponents: [
    ...POPUPS
  ]
})
export class SharedModule {}
