import { Pipe, PipeTransform } from '@angular/core';

/*
 * Sorts string array
*/

@Pipe({ name: 'find', pure: false })
export class FindPipe implements PipeTransform {
  transform(array: Array<any>, find: any, by: any): any {
    // find is a function with return
    if (!array && array.length === 0) {
      return array;
    }
    return array.find(item => find(item, by));
  }
}
