import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { of } from 'rxjs';
import {
  map,
  exhaustMap,
  catchError,
  tap,
} from 'rxjs/operators';

import {
  AuthActions,
  AuthActionTypes,
  LoginToken,
  Login,
  LoginSuccess,
  LoginFailure,
  Logout,
  LogoutConfirmed,
  LogoutComplete,
  LogoutCancelled
} from '@app/auth/store/actions/auth.actions';
import { AuthService } from '@app/auth/services/auth.service';

import { LoginPageComponent } from '@app/auth/components/login-page/login-page.component';

@Injectable()
export class AuthEffects {
  @Effect()
  loginToken$ = this.actions$.pipe(
    ofType<LoginToken>(AuthActionTypes.LoginToken),
    exhaustMap(() =>
      this._AuthService.check().pipe(
        map(resp => new LoginSuccess({ user: resp })),
        catchError(error => of(new LoginFailure(error)))
      )
    )
    );

  @Effect()
  login$ = this.actions$.pipe(
    ofType<Login>(AuthActionTypes.Login),
    map(action => action.payload),
    exhaustMap(auth =>
      this._AuthService.login(auth).pipe(
        map(resp => {
          this._AuthService.saveToken(resp.token);
          return new LoginSuccess({ user: resp.user });
        }),
        catchError(error => of(new LoginFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  loginRedirect$ = this.actions$.pipe(
    ofType<LoginSuccess>(AuthActionTypes.LoginSuccess),
    tap((modal) => {
      const path = window.location.hash.substring(1);
      if (path === '/auth/login') {
        this._Router.navigate(['/loads']);
      } else {
        let queryObj = {};
        if (path.search(/'?'/) !== -1) {
          queryObj = {
            queryParams:
            JSON.parse('{"' + decodeURI(`${path.split('?')[1]}`).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
          };
        }
        this._Router.navigate([`${path.split('?')[0]}`], queryObj);
      }
    })
  );

  @Effect({ dispatch: false })
  loginRequest$ = this.actions$.pipe(
    ofType(AuthActionTypes.LoginRequest),
    tap(authed => {
      const path = window.location.hash.substring(1);
      if (path === '' || path === '/' || path === '/auth/login') {
        this._Router.navigate(['/auth/login']);
      } else {
        this._MatDialog.open(LoginPageComponent, { width: '500px' });
      }
    })
  );

  @Effect()
  logoutConfirmation$ = this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.Logout),
    map(() => new LogoutConfirmed()));

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType<LogoutConfirmed>(AuthActionTypes.LogoutConfirmed),
      exhaustMap(auth =>
        this._AuthService.logout().pipe(
          tap(() => this._Router.navigate(['/auth/login'])),
          map(() => new LogoutComplete()),
          catchError(() => of(new LogoutComplete()))
        )
      )
    );

  constructor(
    private actions$: Actions,
    private _AuthService: AuthService,
    private _Router: Router,
    public _MatDialog: MatDialog
  ) {}
}
