import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

import { ApiService } from '@app/shared/services/api.service';
import { Authenticate } from '@app/auth/models/authentication.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  path = `/auth`;

  cachedRequests: Array<HttpRequest<any>> = [];

  constructor(private apiService: ApiService) {}

  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }

  login(auth: Authenticate): Observable<any> {
    return this.apiService.post(`${this.path}/email`, auth);
    // if (auth.username !== 'tony') {
    //   return throwError('Invalid username or password');
    // }

    // this.loggedIn = true;
    // return of(mockUser);
  }

  logout() {
    this.destroyToken();
    // TODO: expire Token on server.
    return of(true);
  }

  check() {
    return this.apiService.get(`/user/me`);
  }

  getToken(): String {
    return window.localStorage['jwtToken'];
  }

  saveToken(token: String) {
    window.localStorage['jwtToken'] = token;
  }

  destroyToken() {
    window.localStorage.removeItem('jwtToken');
  }
}
