import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of, timer } from 'rxjs';
import { map, mergeMap, switchMap, catchError } from 'rxjs/operators';

import * as toastsActions from '@app/core/store/actions/toast.actions';
import { Toast } from '@app/core/models/toast.model';
import { ToastHelper } from '@app/core/services/toast.service';

@Injectable()
export class ToastEffects {
  @Effect()
  ok$: Observable<Action> = this.actions$.pipe(
    ofType<toastsActions.ToastsOk>(
      toastsActions.ToastActionTypes.OK
    ),
    map(action => {
      return new toastsActions.ToastsAdd(
        this.notify.build('success', action.payload)
      );
    })
  );

  @Effect()
  error$: Observable<Action> = this.actions$.pipe(
    ofType<toastsActions.ToastsError>(
      toastsActions.ToastActionTypes.ERROR
    ),
    map(action => {
      return new toastsActions.ToastsAdd(
        this.notify.build('danger', action.payload)
      );
    })
  );

  @Effect()
  dismiss$: Observable<Action> = this.actions$.pipe(
    ofType<toastsActions.ToastsAdd>(
      toastsActions.ToastActionTypes.ADD
    ),
    mergeMap(action => {
      const toast = action.payload;
      // console.log('[Dismiss] Call', toast);
      return timer(toast.seconds * 1000).pipe(
        switchMap(() => {
          // console.log('[Dismiss] Resolve', toast);
          return of(
            new toastsActions.ToastsDismiss(toast)
          );
        })
      );
    })
  );

  constructor(private actions$: Actions, private notify: ToastHelper) {}
}
