import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnInit
} from '@angular/core';
import { PermissionService } from '../services/permission.service';

@Directive({
  selector: '[appCanAccess]'
})
export class CanAccessDirective implements OnInit {
  @Input()
  public appCanAccess: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.permissionService.checkPermission(this.appCanAccess)
      .subscribe(permissionStatus => permissionStatus ?
        this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear());
  }
}
