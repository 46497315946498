import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Injectable()
export class ApiService {
  private loadNumeric = 0;
  loadSubject: Subject<number> = new Subject<number>();
  constructor(private http: HttpClient) {}

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    return new HttpHeaders(headersConfig);
  }

  private setHeadersForm(): HttpHeaders {
    const headersConfig = {
      Accept: 'application/json'
    };

    return new HttpHeaders(headersConfig);
  }

  private setHeadersFormPut(): HttpHeaders {
    const headersConfig = {
      Accept: 'application/json',
      'Content-Type': 'multipart/form'
    };

    return new HttpHeaders(headersConfig);
  }

  private formatErrors(error: any) {
    this.loadSubject.next(this.loadNumeric = this.loadNumeric + 1);
    return throwError(error.error.message || error);
  }

  private increment() {
    this.loadSubject.next(this.loadNumeric = this.loadNumeric + 1);
  }
  private decrement() {
    this.loadSubject.next(this.loadNumeric = this.loadNumeric - 1);
  }

  private payloadToForm(data) {
    const formData = new FormData();
    for (const key of Object.keys(data)) {
      formData.append(key, data[key]);
    }
    return formData;
  }

  getRaw(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    this.decrement();
    const headers = this.setHeaders();
    headers.set('Content-Type', 'application/pdf');
    return this.http
      .get(`${environment.apiUrl}${path}`, {
        headers: headers,
        params: params
      })
      .pipe(
        finalize(() => this.increment()),
        catchError(this.formatErrors)
      );
  }

  getPdf(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    // const headers = this.setHeaders();
    this.decrement();
    const headers = new HttpHeaders({
      'Content-Type': 'application/pdf',
      Accept: 'application/pdf'
    });
    return this.http
      .get(`${environment.apiUrl}${path}`, {
        headers: headers,
        params: params,
        responseType: 'blob'
        // observe: 'response'
      })
      .pipe(
        finalize(() => this.increment()),
        catchError(this.formatErrors)
      );
  }

  get(path: string, params = {}): Observable<any> {
    this.decrement();
    let httpParams = new HttpParams();
    Object.keys(params).forEach(function(key) {
      httpParams = httpParams.append(key, params[key]);
    });
    return this.http
      .get<any>(`${environment.apiUrl}${path}`, {
        headers: this.setHeaders(),
        params: httpParams
      })
      .pipe(
        finalize(() => this.increment()),
        catchError(this.formatErrors)
      );
  }

  getOpen(
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    this.decrement();
    return this.http.get<any>(path).pipe(
      finalize(() => this.increment()),
      catchError(this.formatErrors)
    );
  }

  put(path: string, payload: Object = {}): Observable<any> {
    this.decrement();
    return this.http
      .put<any>(`${environment.apiUrl}${path}`, JSON.stringify(payload), {
        headers: this.setHeaders()
      })
      .pipe(
        finalize(() => this.increment()),
        catchError(this.formatErrors)
      );
  }
  putForm(path: string, payload: Object = { _id: '' }): Observable<any> {
    this.decrement();
    return this.http
      .put<any>(`${environment.apiUrl}${path}`, this.payloadToForm(payload), {
        headers: this.setHeadersForm()
      })
      .pipe(
        finalize(() => this.increment()),
        catchError(this.formatErrors)
      );
  }

  post(path: string, payload: Object = {}): Observable<any> {
    this.decrement();
    return this.http
      .post<any>(`${environment.apiUrl}${path}`, JSON.stringify(payload), {
        headers: this.setHeaders()
      })
      .pipe(
        finalize(() => this.increment()),
        catchError(this.formatErrors)
      );
  }

  getFile(
    path: string,
    payload: Object = {}
    // params: HttpParams = new HttpParams()
  ): Observable<any> {
    this.decrement();
    return this.http
      .post(`${environment.apiUrl}${path}`, payload, {
        // params: params,
        responseType: 'blob',
        observe: 'response'
      })
      .pipe(
        finalize(() => this.increment()),
        catchError(error => this.formatErrors(error))
      );
    // .subscribe(
    //   resp => {
    //     // Here, resp is of type HttpResponse<MyJsonData>.
    //     // You can inspect its headers:
    //     // console.log(resp.headers.get('X-Custom-Header'));
    //     // And access the body directly, which is typed as MyJsonData as requested.
    //     // console.log(resp.body);
    //   },
    //   err => {
    //     throw err;
    //   }
    // );
  }
  postForm(path: string, payload): Observable<any> {
    this.decrement();
    return this.http
      .post<any>(`${environment.apiUrl}${path}`, this.payloadToForm(payload), {
        headers: this.setHeadersForm()
      })
      .pipe(
        finalize(() => this.increment()),
        catchError(this.formatErrors)
      );
  }
  postPdf(path: string, payload: Object = {}): Observable<any> {
    this.decrement();
    return this.http
      .post<any>(`${environment.apiUrl}${path}`, { headers: this.setHeaders() })
      .pipe(
        finalize(() => this.increment()),
        catchError(this.formatErrors)
      );
  }

  delete(path): Observable<any> {
    this.decrement();
    return this.http
      .delete<any>(`${environment.apiUrl}${path}`, {
        headers: this.setHeaders()
      })
      .pipe(
        finalize(() => this.increment()),
        catchError(this.formatErrors)
      );
  }
}
