import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

export class LifeSubjectDestroyer implements OnDestroy {
    destroyed$ = new Subject<boolean>();

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
      }
}