import { Equipment } from '@app/shared/models/equipment.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  EquipmentActions,
  EquipmentActionTypes
} from '@app/core/store/actions/equipment.actions';

export interface State extends EntityState<Equipment> {
  selectId: string | null;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<Equipment> = createEntityAdapter<Equipment>({
  selectId: (equipment: Equipment) => equipment._id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectId: null,
  loaded: false,
  loading: false
});

export function reducer(state = initialState, action: EquipmentActions): State {
  switch (action.type) {
    case EquipmentActionTypes.GET_SUCCESS: {
      return adapter.addAll(action.payload, {
        ...state,
        selectId: state.selectId,
        loaded: true,
        loading: false
      });
    }

    case EquipmentActionTypes.GET_ONE_SUCCESS: {
      return adapter.addOne(action.payload.equipment, {
        ...state,
        selectId: state.selectId,
        loaded: true,
        loading: false
      });
    }

    case EquipmentActionTypes.SELECT: {
      return {
        ...state,
        selectId: action.payload
      };
    }

    case EquipmentActionTypes.CREATE_CONTACT_SUCCESS: {
      return adapter.addOne(action.payload.equipment, { ...state, loaded: true });
    }

    case EquipmentActionTypes.UPDATE: {
      return adapter.updateOne(action.payload.equipment, state);
    }

    case EquipmentActionTypes.UPDATE_SUCCESS: {
      return adapter.updateOne(action.payload.equipment, state);
    }

    case EquipmentActionTypes.DELETE: {
      return adapter.removeOne(action.payload, state);
    }

    default: {
      return state;
    }
  }
}
export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getSelectedId = (state: State) => state.selectId;
export const {
  // select the array of Equipment ids
  selectIds: selectEquipmentIds,
  // select the dictionary of Equipment entities
  selectEntities: selectEquipmentEntities,
  // select the array of equipments
  selectAll: selectAllEquipment,
  // select the total Equipment count
  selectTotal: selectEquipmentTotal
} = adapter.getSelectors();
