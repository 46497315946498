import { createSelector } from '@ngrx/store';

import * as fromRoot from '@app/core/store/selectors/router.selectors';
import * as fromContacts from '@app/core/store/selectors/contact.selectors';
import * as fromFiles from '@app/core/store/selectors/file.selectors';
import * as fromFeature from '@app/core/store/reducers';
import * as fromOrder from '@app/core/store/reducers/order.reducers';
// Orders State

export const selectOrderState = createSelector(
  fromFeature.selectCoreState,
  (state: fromFeature.State) => state.orders
);

export const getOrderLoaded = createSelector(
  selectOrderState,
  fromOrder.getLoaded
);
export const getOrderLoading = createSelector(
  selectOrderState,
  fromOrder.getLoading
);

export const selectOrderIds = createSelector(
  selectOrderState,
  fromOrder.selectOrderIds
);
export const selectOrderEntities = createSelector(
  selectOrderState,
  fromOrder.selectOrderEntities
);
export const selectAllOrders = createSelector(
  selectOrderState,
  fromOrder.selectAllOrder
);
export const selectOrderTotal = createSelector(
  selectOrderState,
  fromOrder.selectOrderTotal
);
export const selectCurrentOrderId = createSelector(
  selectOrderState,
  fromOrder.getSelectedId
);

export const selectCurrentOrder = createSelector(
  selectOrderEntities,
  selectCurrentOrderId,
  (entities, orderId) => entities[orderId]
);

export const selectedOrder = createSelector(
  selectOrderEntities,
  fromRoot.getRouterState,
  (entities, router) => {
    return router.state && entities[router.state.params.orderId];
  }
);

export const selectUsersContactIds = createSelector(
  selectedOrder,
  order => {
    if (order && order.contacts) {
      return order.contacts;
    }
  }
);
export const selectOrderContacts = createSelector(
  selectUsersContactIds,
  fromContacts.selectContactEntities,
  (contacts, contactsEntities) => {
    if (contacts && Object.values(contactsEntities).length) {
      return {
        billing: contactsEntities[contacts.billing as string],
        carrier: contactsEntities[contacts.carrier as string],
        dispatcher: contactsEntities[contacts.dispatcher as string],
        drivers: (contacts.drivers as string[]).map(driverId => contactsEntities[driverId])
      };
    }
  }
);

export const selectOrderFiles = createSelector(
  selectedOrder,
  fromFiles.selectFilesByResource('orders'),
  (order, files) => order && order._id ? files.filter(file => file.resourceId === order._id) : []
);

export const selectConfirmationFile = createSelector(
  selectedOrder,
  fromFiles.selectFileEntities,
  (order, files) => {
    if (order && order.confirmationFileId) {
      return files[order.confirmationFileId];
    }
  }
);

export const selectDetailsFile = createSelector(
  selectedOrder,
  fromFiles.selectFileEntities,
  (order, files) => {
    if (order && order.detailsFileId) {
      return files[order.detailsFileId];
    }
  }
);
