import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/services/api.service';

import { Contact } from '@app/shared/models/contact.model';
@Injectable()
export class ContactService {
  path = `/contacts`;
  constructor(private apiService: ApiService) {}

  get(obId) {
    return this.apiService.get(this.path + `/${obId}`);
  }
  getByIds(ids) {
    return this.apiService.get(this.path + `/${ids.join(',')}`);
  }

  getAll(query) {
    return this.apiService.get(this.path, query);
  }

  search(query) {
    return this.apiService.get(`${this.path}-search`, query);
  }
  create(ob) {
    return this.apiService.post(this.path, ob);
  }

  createSub(parentId: string, ob: Contact) {
    return this.apiService.post(`${this.path}/${parentId}${this.path}`, ob);
  }

  update(data: Contact) {
    Object.keys(data)
      .filter(k => k !== '_id' && k.startsWith('_'))
      .map(k => {
        console.log('delete', k);
        delete data[k];
      });
    return this.apiService.put(`${this.path}/${data._id}`, data);
  }

  delete(id: string) {
    return this.apiService.delete(`${this.path}/${id}`);
  }

  deleteMany(ids: string[]) {
    return this.apiService.post(`${this.path}/removeByIds`, {ids});
  }
}
