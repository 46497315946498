import {
  Component,
  AfterViewInit,
  EventEmitter,
  OnDestroy,
  Output,
  ElementRef,
  NgZone,
  Input,
  ChangeDetectorRef
} from '@angular/core';

import { AuthService } from '@app/auth/services/auth.service';
import { environment } from 'environments/environment';

declare var $: any;
declare var require: any;
const Dropzone = require('../../../../../node_modules/dropzone/dist/dropzone-amd-module');

@Component({
  selector: 'app-dropzone',
  templateUrl: 'dropzone.component.html',
  styleUrls: ['dropzone.component.css']
})
export class DropzoneComponent implements AfterViewInit, OnDestroy {
  @Input() resource;
  @Input() resourceId;
  @Input() permissions;
  @Output() success: EventEmitter<any> = new EventEmitter<any>();
  @Output() error: EventEmitter<any> = new EventEmitter<any>();
  // TODO: acceptedFiles option as input

  dropzone;
  uploading = false;
  fileCount = 0;

  constructor(
    public element: ElementRef,
    private _NgZone: NgZone,
    private ref: ChangeDetectorRef,
    private _AuthService: AuthService
  ) {}

  get fileDropped(): boolean {
    if (this.dropzone) {
      return this.dropzone.files.length > 0;
    }
    return false;
  }

  ngAfterViewInit() {
    const droparea = this.element.nativeElement.querySelector(
      '.dropzone-drop-area'
    );
    // droparea.addEventListener('paste', this.onPaste);

    this.dropzone = new Dropzone(this.element.nativeElement, {
      url: `${environment.apiUrl}/files`,
      init: function() {},
      params: {
        resource: this.resource,
        resourceId: this.resourceId,
        permissions: this.permissions
      },
      headers: {
        Authorization: `Bearer ${this._AuthService.getToken()}`,
        'Cache-Control': null,
        'X-Requested-With': null
        // 'Content-Type': 'multipart/form-data'
      },
      // addRemoveLinks: true,
      paramName: 'file',
      autoProcessQueue: false,
      uploadMultiple: true,
      parallelUploads: 20,
      hiddenInputContainer: droparea,
      dictDefaultMessage: '',
      maxFiles: 20,
      // acceptedFiles: 'audio/*,image/*,.psd,.pdf',
      clickable: droparea,
      previewsContainer: droparea,
      previewTemplate: `
      <div id="template-preview">
      <div class="dz-preview dz-file-preview well" id="dz-preview-template">
          <div class="dz-details">
              <i class="far fa-file-alt" style="font-size:24px;"></i>
              <div class="dz-filename" style="display: inline;">
                  <span data-dz-name></span>
              </div>
              <div class="dz-size" data-dz-size style="display: inline; padding-right:5px;"></div>
              <button mat-button data-dz-remove=""><i class="fas fa-times"></i></button>
          </div>
          <div class="dz-progress">
              <span class="dz-upload" data-dz-uploadprogress></span>
          </div>
          <div class="dz-success-mark">
              <span></span>
          </div>
          <div class="dz-error-mark">
              <span></span>
          </div>
          <div class="dz-error-message">
              <span data-dz-errormessage></span>
          </div>
      </div>
  </div>
      `
    });
    this.dropzone.autoDiscover = false;

    this.dropzone.on('addedfile', file => {
      /*file.previewElement.addEventListener('click', () => {
       this.dropzone.removeFile(file);
       });*/
      // console.log('[File Added]', this.fileCount, this.dropzone.files.length);
      this._NgZone.run(() => {
        this.fileCount = this.dropzone.files.length;
        // console.log('[Count Update]', this.fileCount);
        this.ref.detectChanges();
      });
    });

    this.dropzone.on('success', (files, response) => {
      // console.log(this.fileCount);
      this._NgZone.run(() => {
        this.uploading = false;
        this.fileCount = 0;
        this.success.emit(response);
      });
      this.dropzone.removeAllFiles();
    });
    // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
    // of the sending event because uploadMultiple is set to true.
    this.dropzone.on('sendingmultiple', () => {
      this._NgZone.run(() => (this.uploading = true));
      // console.log('sending!!!!!!!');
    });

    this.dropzone.on('removedfile', _ => {
      this._NgZone.run(() => {
        this.fileCount = this.dropzone.files.length;
        this.ref.detectChanges();
      });
    });
  }

  onPaste(event) {
    console.log('onPaste', event);
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    for (const index in items) {
      if (items.hasOwnProperty(index)) {
        const item = items[index];
        if (item.kind === 'file') {
          // adds the file to your dropzone instance
          this.dropzone.addFile(item.getAsFile());
          // this.dropzone.emit('addedfile', item.getAsFile());
          //this.dropzone.emit("thumbnail", item.getAsFile(), "/image/url");
          // this.dropzone.emit('complete', item.getAsFile());
        }
      }
    }
  }

  ngOnDestroy() {
    this.dropzone.disable();
  }

  upload() {
    this.dropzone.processQueue();
  }
}
