export interface Email {
  _id?: string;
  value: string;
  type: string;
  notify?: boolean;
  createdAt?: string;
}

export function genMockEmail(): Email {
  return {
    value: '',
    type: 'Work',
    notify: false
  };
}
