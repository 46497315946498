import { Stop } from '@app/shared/models/stop.model';
import { Contact } from '@app/shared/models/contact.model';

export class Order {
  _id?: string;
  contacts: {
    billing: string,
    carrier: string,
    drivers: string[],
    dispatcher: string,
  };
  _contacts?: {
    billing: Contact,
    carrier: Contact,
    drivers: Contact[],
    dispatcher: Contact,
  };
  ref?: string;
  number?: number;
  status?: string;
  createdAt?: string;
  stops?: {
    from: Stop,
    intermediate: [Stop],
    to: Stop
  };
  payment: {
    customer: {
      amount: number,
      terms: number
    },
    carrier: {
      amount: number,
      terms: number
    }
  };
  confirmationFileId?: string;
  detailsFileId?: string;
  creator?: string | Contact;
}

export function genMockOrder(): Order {
  return {
    contacts: {
      billing: null,
      carrier: null,
      drivers: [],
      dispatcher: null
    },
    payment: {
      customer: {
        amount: 0,
        terms: 0
      },
      carrier: {
        amount: 0,
        terms: 0
      }
    }
  };
}
