import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, take, mergeMap, catchError } from 'rxjs/operators';
import * as Auth from '@app/auth/store/actions';
import * as fromAuth from '@app/auth/store';
import { AuthService } from '@app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private store: Store<fromAuth.State>,
    private router: Router,
    private _AuthService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return this.checkStoreAuthentication().pipe(
      mergeMap(user => {
        if (user) {
          return of(user);
        }
        return this.checkApiAuthentication();
      }),
      map(user => {
        if (!user) {
          this.store.dispatch(new Auth.LoginRequest());
          return false;
        }
        if (route.data && route.data.action && user.permissions) {
          return user.permissions.includes(route.data.action);
        }
        return true;
      })
    );
  }

  checkStoreAuthentication() {
    return this.store.select(fromAuth.selectAuthUser).pipe(take(1));
  }

  checkApiAuthentication() {
    return this._AuthService.check().pipe(
      map(user => {
        if (user) {
          this.store.dispatch(new Auth.LoginSuccess({ user }));
        }
        return user;
      }),
      catchError(() => of(false))
    );
  }
}
