import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Equipment } from '@app/shared/models/equipment.model';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-equipment-modal',
  templateUrl: './equipment-modal.component.html',
  styleUrls: ['./equipment-modal.component.css']
})
export class EquipmentModalComponent implements OnInit {
  set equipment(value: Equipment) {
    if (this.form) {
      this.form.patchValue(value);
    }
    this._equipment = value;
  }

  _equipment: Equipment;

  form: FormGroup;

  fuelTypes = ['Diesel', 'Gas'];

  constructor(
    public dialogRef: MatDialogRef<EquipmentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.equipment = data;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      status: new FormControl(this._equipment.status, [Validators.required]),
      type: new FormControl(this._equipment.type, [Validators.required]),
      startDate: new FormControl(this._equipment.startDate, [Validators.required]),
      endDate: new FormControl(this._equipment.endDate, [Validators.required]),
      unit: new FormControl(this._equipment.unit, [Validators.required]),
      year: new FormControl(this._equipment.year, [Validators.required]),
      make: new FormControl(this._equipment.make, [Validators.required]),
      model: new FormControl(this._equipment.model, [Validators.required]),
      vin: new FormControl(this._equipment.vin, [Validators.required]),
      plate: new FormControl(this._equipment.plate, [Validators.required]),
      state: new FormControl(this._equipment.state, [Validators.required]),
      fuelType: new FormControl(this._equipment.fuelType),
      axles: new FormControl(this._equipment.axles),
      wLoaded: new FormControl(this._equipment.wLoaded),
      wUnloaded: new FormControl(this._equipment.wUnloaded),
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      // this.notify.error('Please enter valid form inputs!');
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      return;
    }
    this.dialogRef.close({ ...this._equipment, ...this.form.value });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
