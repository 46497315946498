import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/auth/store/reducers';
import * as fromAuth from '@app/auth/store/reducers/auth.reducers';

export const selectAuthState = createSelector(
  fromFeature.featureSelectAuthState,
  (state: fromFeature.State) => state.auth
);

export const selectAuthUser = createSelector(
  selectAuthState,
  fromAuth.selectUser
);
export const selectIsLoggedIn = createSelector(selectAuthUser, user => !!user);
