import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as Reducer from '@app/core/store/reducers';
import * as NAction from '@app/core/store/actions/toast.actions';
import { Toast } from '@app/core/models/toast.model';
import * as uuid from 'uuid/v4';

@Injectable()
export class ToastHelper {
  constructor(private store: Store<Reducer.State>) {}

  ok(msg: string, seconds?: number) {
    this.add('success', msg, seconds);
  }

  error(msg: string, seconds?: number) {
    seconds = 60000;
    this.add('danger', msg, seconds);
  }

  info(msg: string, seconds?: number) {
    this.add('info', msg, seconds);
  }

  warn(msg: string, seconds?: number) {
    this.add('warning', msg, seconds);
  }

  add(type, msg, seconds) {
    seconds = seconds || 5;
    this.dispatch({ id: uuid(), type, msg, seconds });
  }

  build(type, msg, seconds?: number): Toast {
    seconds = seconds || 5;
    return { id: uuid(), type, msg, seconds };
  }

  private dispatch(toast) {
    this.store.dispatch(new NAction.ToastsAdd(toast));
  }

  close(toast: Toast) {
    this.store.dispatch(new NAction.ToastsDismiss(toast));
  }
}
