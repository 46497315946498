import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/services/api.service';

@Injectable()
export class OrderService {
  path = `/orders`;
  constructor(private apiService: ApiService) {}

  get(orderId) {
    return this.apiService.get(this.path + `/${orderId}`);
  }

  getAll(filters) {
    return this.apiService.get(this.path, filters);
  }

  create(order) {
    return this.apiService.post(this.path, order);
  }

  update(data) {
    const order = JSON.parse(JSON.stringify(data));
    const _id = data._id;
    delete order._id;
    delete order.vehicles;
    delete order.followUpDays;
    delete order.orderNumber;
    delete order.quoteNumber;
    delete order.deleted;
    delete order.__v;
    delete order._contacts;
    return this.apiService.put(`${this.path}/${_id}`, order);
  }

  delete(_id) {
    return this.apiService.delete(`${this.path}/${_id}`);
  }

  deleteMany(ids: string[]) {
    return this.apiService.post(`${this.path}/removeByIds`, {ids});
  }
}
