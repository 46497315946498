import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { File } from '@app/shared/models/file.model';

export enum AvatarActionTypes {
  GET = '[Avatar] GET',
  GET_SUCCESS = '[Avatar] GET_SUCCESS',
  GET_ERROR = '[Avatar] GET_ERROR',

  GET_ONE = '[Avatar] GET_ONE',
  GET_ONE_SUCCESS = '[Avatar] GET_ONE_SUCCESS',
  GET_ONE_ERROR = '[Avatar] GET_ONE_ERROR',

  DELETE = '[Avatar] DELETE',
  DELETE_SUCCESS = '[Avatar] DELETE_SUCCESS',
  DELETE_ERROR = '[Avatar] DELETE_ERROR',

  UPSERT = '[Avatar] UPSERT',
  UPSERT_SUCCESS = '[Avatar] UPSERT_SUCCESS',
  UPSERT_ERROR = '[Avatar] UPSERT_ERROR',

  API_DOWNLOAD = '[API Avatar] DOWNLOAD',
  API_DOWNLOAD_SUCCESS = '[API Avatar] DOWNLOAD_SUCCESS',
  API_DOWNLOAD_ERROR = '[API Avatar] DOWNLOAD_ERROR',
}

// Actions for Getting  Avatars
export class GetAvatars implements Action {
  readonly type = AvatarActionTypes.GET;
  constructor(
    public payload: {
      resourceId: string | string[];
    }
  ) {}
}
export class GetAvatarsSuccess implements Action {
  readonly type = AvatarActionTypes.GET_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: Avatar[]) {}
  constructor(public payload: { avatars: File[] }) {}
}
export class GetAvatarsError implements Action {
  readonly type = AvatarActionTypes.GET_ERROR;
  constructor(public payload: any) {}
}

export class GetAvatar implements Action {
  readonly type = AvatarActionTypes.GET_ONE;
  constructor(public payload: any) {}
}
export class GetAvatarSuccess implements Action {
  readonly type = AvatarActionTypes.GET_ONE_SUCCESS;

  constructor(public payload: { avatar: File }) {}
}
export class GetAvatarError implements Action {
  readonly type = AvatarActionTypes.GET_ONE_ERROR;
  constructor(public payload: any) {}
}

export class UpsertAvatar implements Action {
  readonly type = AvatarActionTypes.UPSERT;

  constructor(public payload: { avatar: File }) {}
}
export class UpsertAvatarSuccess implements Action {
  readonly type = AvatarActionTypes.UPSERT_SUCCESS;

  constructor(public payload: { avatar: File }) {}
}
export class UpsertAvatarError implements Action {
  readonly type = AvatarActionTypes.UPSERT_ERROR;

  constructor(public payload: any) {}
}

export class DeleteAvatar implements Action {
  readonly type = AvatarActionTypes.DELETE;

  constructor(public payload: File) {}
}
export class DeleteAvatarSuccess implements Action {
  readonly type = AvatarActionTypes.DELETE_SUCCESS;

  constructor(public payload: any) {}
}
export class DeleteAvatarError implements Action {
  readonly type = AvatarActionTypes.DELETE_ERROR;

  constructor(public payload: File) {}
}
export class ApiDownloadAvatar implements Action {
  readonly type = AvatarActionTypes.API_DOWNLOAD;

  constructor(public payload: { avatar: File; preview: boolean }) {}
}
export class ApiDownloadAvatarSuccess implements Action {
  readonly type = AvatarActionTypes.API_DOWNLOAD_SUCCESS;

  constructor(public payload: any) {}
}
export class ApiDownloadAvatarError implements Action {
  readonly type = AvatarActionTypes.API_DOWNLOAD_ERROR;

  constructor(public payload: any) {}
}

export type AvatarActions =
  | GetAvatar
  | GetAvatarSuccess
  | GetAvatarError

  | UpsertAvatar
  | UpsertAvatarSuccess
  | UpsertAvatarError

  | GetAvatars
  | GetAvatarsSuccess
  | GetAvatarsError

  | DeleteAvatar
  | DeleteAvatarSuccess
  | DeleteAvatarError

  | ApiDownloadAvatar
  | ApiDownloadAvatarSuccess
  | ApiDownloadAvatarError;
