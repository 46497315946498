import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';

import { ToastHelper } from '@app/core/services/toast.service';
import { Contact, Order } from '@app/shared/models';

@Component({
  selector: 'app-order-table',
  templateUrl: './order-table.component.html',
  styleUrls: ['./order-table.component.scss']
})
export class OrderTableComponent implements OnInit {
  @ViewChild(MatSort, { static: false })
  sort: MatSort;
  @ViewChild(MatPaginator, { static: false })
  paginator: MatPaginator;

  @Input()
  set orders(val: Order[]) {
    this.orderTable.data = val;
  }
  @Input()
  contacts: Contact[];
  @Input()
  count: number;
  @Input()
  filters: any = {
    number: '',
    status: '',
    pickup: '',
    delivery: '',
    refNum: '',
    carrierName: '',
    carrierId: '',
    driverName: '',
    driverId: '',
    itemsPrePage: 10,
    pageIndex: 0,
    _enabled: false
  };
  @Input()
  displayedColumns: string[] = ['select', 'number', 'status', 'pickup', 'delivery', 'carrier', 'customer', 'interaction'];

  @Output()
  createNewOrder = new EventEmitter<void>();
  @Output()
  deleteAllSelected = new EventEmitter<string[]>();
  @Output()
  filterChanged = new EventEmitter<any>();

  orderTable: MatTableDataSource<Order> = new MatTableDataSource([]);
  selection = new SelectionModel<Order>(true, []);

  constructor(
    private _Router: Router,
    public notify: ToastHelper,
  ) {
  }

  ngOnInit() {
    setTimeout(() => this.orderTable.sort = this.sort, 100);
    this.orderTable.filterPredicate = (data: Order) => {
      let returnVal = false;
      if (
        Boolean(data.number.toString().indexOf(this.filters.number) + 1) &&
        Boolean(
          (data.status || '')
            .toUpperCase()
            .indexOf(this.filters.status.toUpperCase()) + 1
        ) &&
        Boolean(
          (data.ref || '')
            .toUpperCase()
            .indexOf(this.filters.refNum.toUpperCase()) + 1
        )
        // && Boolean(data.contacts.carrierId.indexOf(this.filters.carrierId) + 1)
        // && Boolean(data.contacts.driverIds.indexOf(this.filters.carrierId) + 1)
      ) {
        returnVal = true;
      }
      return returnVal;
    };
  }

  carrierFilterChange(carrier: Contact) {
    this.filters.carrierName = carrier.name;
    this.filters.carrierId = carrier._id;
    this.onFilterChanged();
  }

  driverFilterChange(driver: Contact) {
    this.filters.driverName = driver.name;
    this.filters.driverId = driver._id;
    this.onFilterChanged();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.orderTable.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.orderTable.filteredData.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Order): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.number
    }`;
  }

  edit(orderId: string) {
    this._Router.navigate(['/loads/' + orderId]);
  }

  onCreateNewOrder() {
    this.createNewOrder.emit();
  }

  onDeleteAllSelected() {
    const ids = this.selection.selected.map(order => order._id);
    this.deleteAllSelected.emit(ids);
    this.selection.clear();
  }

  onFilterChanged() {
    this.orderTable.filter = '1';
    this.filterChanged.emit(this.filters);
  }

  cancel() {
    for (const filterProp of Object.keys(this.filters)) {
      if (!filterProp.startsWith('_')) {
        this.filters[filterProp] = '';
      }
    }
    this.onFilterChanged();
  }

  changePage(event) {
    this.filters.pageIndex = event.pageIndex;
    this.filters.itemsPrePage = event.pageSize;
    this.onFilterChanged();
  }
}
