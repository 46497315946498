import { Component } from '@angular/core';
declare var require: any;
@Component({
  selector: 'app-layout-footer',
  templateUrl: './footer.component.html',
  styles: [
    `
      .footer {
        position: relative;
        bottom: 0;
        width: 100%;
        /* Set the fixed height of the footer here */
        height: 60px;
        line-height: 60px; /* Vertically center the text there */
        background-color: #f5f5f5;
      }
    `
  ]
})
export class FooterComponent {
  appVersion = require('../../../../../package.json').version;
  today: number = Date.now();
}
