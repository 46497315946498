import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/core/store/reducers';
import * as fromRouter from '@app/core/store/selectors/router.selectors';
import * as fromMailNotify from '@app/core/store/reducers/emailNotify.reducers';
// EmailNotifies State

export const selectMailNotifyState = createSelector(
  fromFeature.selectCoreState,
  (state: fromFeature.State) => state.emailNotify
);

export const getMailNotifyLoaded = createSelector(
  selectMailNotifyState,
  fromMailNotify.getLoaded
);
export const getMailNotifyLoading = createSelector(
  selectMailNotifyState,
  fromMailNotify.getLoading
);

export const selectMailNotifyIds = createSelector(
  selectMailNotifyState,
  fromMailNotify.selectMailNotifyIds
);
export const selectMailNotifyEntities = createSelector(
  selectMailNotifyState,
  fromMailNotify.selectMailNotifyEntities
);
export const selectAllEmailNotifies = createSelector(
  selectMailNotifyState,
  fromMailNotify.selectAllMailNotify
);
export const selectMailNotifyTotal = createSelector(
  selectMailNotifyState,
  fromMailNotify.selectMailNotifyTotal
);
export const selectCurrentMailNotifyId = createSelector(
  selectMailNotifyState,
  fromMailNotify.getSelectedId
);

export const selectCurrentMailNotify = createSelector(
  selectMailNotifyEntities,
  selectCurrentMailNotifyId,
  (entities, emailNotifyId) => entities[emailNotifyId]
);

export const selectedMailNotify = createSelector(
  selectMailNotifyEntities,
  fromRouter.getRouterState,
  (entities, router) => {
    return router.state && entities[router.state.params.emailNotifyId];
  }
);

