import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  map,
  tap,
  switchMap,
  mergeMap,
  flatMap,
  catchError
} from 'rxjs/operators';

import * as FileActions from '@app/core/store/actions/file.actions';
import * as NotifyActions from '@app/core/store/actions/toast.actions';

import { FileService } from '@app/core/services/file.service';
import { ToastHelper } from '@app/core/services/toast.service';

@Injectable()
export class FileEffects {
  @Effect()
  getFiles$ = this.actions$.pipe(
    ofType<FileActions.GetFile>(FileActions.FileActionTypes.GET),
    switchMap(action => {
      return this._FileService.getAll(action.payload).pipe(
        map(
          resp =>
            new FileActions.GetFilesSuccess({
              files: resp
            })
        ),
        catchError(error => of(new FileActions.GetFilesError(error)))
      );
    })
  );

  @Effect()
  updateFile$ = this.actions$.pipe(
    ofType<FileActions.UpdateFile>(FileActions.FileActionTypes.UPDATE),
    mergeMap(action => {
      return this._FileService.update(action.payload.file).pipe(
        switchMap(resp => [
          new NotifyActions.ToastsOk('File Updated Successful!'),
          new FileActions.UpdateFileSuccess({
            file: { id: resp._id, changes: resp }
          })
        ]),
        catchError(error => {
          this.notify.error(error.message || error);
          return of(new FileActions.UpdateFileError(action.payload.file));
        })
      );
    })
  );

  @Effect()
  deleteFile$ = this.actions$.pipe(
    ofType<FileActions.DeleteFile>(FileActions.FileActionTypes.DELETE),
    mergeMap(action => {
      return this._FileService.delete(action.payload).pipe(
        switchMap((data: Response) => [
          new NotifyActions.ToastsOk('File Deleted Successful!'),
          new FileActions.DeleteFileSuccess(action.payload)
        ]),
        catchError(error => {
          this.notify.error(error);
          return of(new FileActions.DeleteFileError(action.payload));
        })
      );
    })
  );

  @Effect()
  downloadFile$ = this.actions$.pipe(
    ofType<FileActions.ApiDownloadFile>(
      FileActions.FileActionTypes.API_DOWNLOAD
    ),
    mergeMap(action => {
      // Passing Name from component, Name is not sent from server.
      const name = action.payload.file.name;
      return this._FileService.download(action.payload.file).pipe(
        map(resp => {
          const file = this._FileService.getBlobURL({
            ...resp,
            name,
            uploadName: action.payload.file.uploadName
          });

          return { ...action.payload.file, ...file };
        }),
        flatMap(resp => {
          let acts = [
            new FileActions.ApiDownloadFileSuccess({ file: resp }),
            new FileActions.TriggerBrowserDownload(resp)
          ];
          if (action.payload.preview) {
            acts = [new FileActions.ApiDownloadFileSuccess({ file: resp })];
          }
          return acts;
        }),
        catchError(error => {
          this.notify.error(error.message || error);
          return of(new FileActions.ApiDownloadFileError(error));
        })
      );
    })
  );

  @Effect()
  previewFile$ = this.actions$.pipe(
    ofType<FileActions.TriggerBrowserPreview>(
      FileActions.FileActionTypes.TRIGGER_BROWSER_PREVIEW
    ),
    map(
      action =>
        new FileActions.ApiDownloadFile({
          file: action.payload,
          preview: true
        })
    )
  );

  @Effect({ dispatch: false })
  triggerFileDownload$ = this.actions$.pipe(
    ofType<FileActions.TriggerBrowserDownload>(
      FileActions.FileActionTypes.TRIGGER_BROWSER_DOWNLOAD
    ),
    tap(action => {
      const a = document.createElement('a');
      // a.style = 'display: none';
      a.href = action.payload._url;
      a.download = action.payload.name;
      document.body.appendChild(a);
      a.click();
      setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(action.payload._url);
      }, 100);
    })
  );

  constructor(
    private actions$: Actions,
    private _FileService: FileService,
    private notify: ToastHelper
  ) {}
}
