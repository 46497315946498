import { MailNotify } from '@app/shared/models/emailNotify.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  MailNotifyActions,
  MailNotifyActionTypes
} from '@app/core/store/actions/emailNotify.actions';

export interface State extends EntityState<MailNotify> {
  selectId: string | null;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<MailNotify> = createEntityAdapter<MailNotify>({
  selectId: (emailNotify: MailNotify) => emailNotify._id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectId: null,
  loaded: false,
  loading: false
});

export function reducer(state = initialState, action: MailNotifyActions): State {
  switch (action.type) {
    case MailNotifyActionTypes.GET_SUCCESS: {
      return adapter.addAll(action.payload, {
        ...state,
        selectId: state.selectId,
        loaded: true,
        loading: false
      });
    }

    case MailNotifyActionTypes.GET_ONE_SUCCESS: {
      return adapter.addOne(action.payload.emailNotify, {
        ...state,
        selectId: state.selectId,
        loaded: true,
        loading: false
      });
    }

    case MailNotifyActionTypes.SELECT: {
      return {
        ...state,
        selectId: action.payload
      };
    }

    case MailNotifyActionTypes.CREATE_CONTACT_SUCCESS: {
      return adapter.addOne(action.payload.emailNotify, { ...state, loaded: true });
    }

    case MailNotifyActionTypes.UPDATE: {
      return adapter.updateOne(action.payload.emailNotify, state);
    }

    case MailNotifyActionTypes.UPDATE_SUCCESS: {
      return adapter.updateOne(action.payload.emailNotify, state);
    }

    case MailNotifyActionTypes.DELETE: {
      return adapter.removeOne(action.payload, state);
    }

    default: {
      return state;
    }
  }
}
export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getSelectedId = (state: State) => state.selectId;
export const {
  // select the array of MailNotify ids
  selectIds: selectMailNotifyIds,
  // select the dictionary of MailNotify entities
  selectEntities: selectMailNotifyEntities,
  // select the array of emailNotifies
  selectAll: selectAllMailNotify,
  // select the total MailNotify count
  selectTotal: selectMailNotifyTotal
} = adapter.getSelectors();
