import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/services/api.service';

import { File } from '@app/shared/models/file.model';
@Injectable()
export class FileService {
  path = `/files`;
  constructor(private apiService: ApiService) {}

  get(fileId) {
    return this.apiService.get(this.path + `/${fileId}`);
  }

  getAll(query) {
    return this.apiService.get(this.path, query);
  }

  create(file) {
    return this.apiService.post(this.path, file);
  }

  update(data: File) {
    Object.keys(data)
      .filter(k => k !== '_id' && k.startsWith('_'))
      .map(k => {
        console.log('delete', k);
        delete data[k];
      });
    return this.apiService.put(`${this.path}/${data._id}`, data);
  }

  delete(data) {
    return this.apiService.delete(`${this.path}/${data._id}`);
  }

  upload(data) {
    return this.apiService.postForm(`${this.path}`, data);
  }

  download(data) {
    return this.apiService.getFile(`${this.path}/download`, data);
  }

  getBlobURL(payload) {
    const blob = new Blob([payload.body], {
      type: payload.headers.get('content-type')
    });
    // Remove File Ext
    const name = payload.name.replace(/\.[^/.]+$/, '');
    const ext = payload.uploadName.split('.').pop();
    const url = window.URL.createObjectURL(blob);
    return {
      _url: url,
      // name: name + (ext ? `.${ext}` : '')
    };
  }
}
