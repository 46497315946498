import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Equipment } from '@app/shared/models/equipment.model';

export enum EquipmentActionTypes {
  SELECT = '[Equipment] SELECT',

  GET = '[Equipment] GET',
  GET_SUCCESS = '[Equipment] GET_SUCCESS',
  GET_ERROR = '[Equipment] GET_ERROR',

  CREATE_CONTACT = '[Equipment] CREATE_CONTACT',
  CREATE_CONTACT_SUCCESS = '[Equipment] CREATE_CONTACT_SUCCESS',
  CREATE_CONTACT_ERROR = '[Equipment] CREATE_CONTACT_ERROR',

  GET_ONE = '[Equipment] GET_ONE',
  GET_ONE_SUCCESS = '[Equipment] GET_ONE_SUCCESS',
  GET_ONE_ERROR = '[Equipment] GET_ONE_ERROR',

  UPDATE = '[Equipment] UPDATE',
  UPDATE_SUCCESS = '[Equipment] UPDATE_SUCCESS',
  UPDATE_ERROR = '[Equipment] UPDATE_ERROR',

  DELETE = '[Equipment] DELETE',
  DELETE_SUCCESS = '[Equipment] DELETE_SUCCESS',
  DELETE_ERROR = '[Equipment] DELETE_ERROR',

  DELETE_MANY = '[Equipment] DELETE_MANY',
  DELETE_MANY_SUCCESS = '[Equipment] DELETE_MANY_SUCCESS',
  DELETE_MANY_ERROR = '[Equipment] DELETE_MANY_ERROR',
}

// Actions for Getting  Equipments
export class SelectEquipment implements Action {
  readonly type = EquipmentActionTypes.SELECT;
  constructor(public payload: string) {}
}

export class GetEquipments implements Action {
  readonly type = EquipmentActionTypes.GET;
  constructor(public payload: { filters: object }) {}
}
export class GetEquipmentsSuccess implements Action {
  readonly type = EquipmentActionTypes.GET_SUCCESS;
  constructor(public payload: Equipment[]) {}
}
export class GetEquipmentsError implements Action {
  readonly type = EquipmentActionTypes.GET_ERROR;
  constructor(public payload: any) {}
}

// Action for Creating Equipment
export class CreateEquipment implements Action {
  readonly type = EquipmentActionTypes.CREATE_CONTACT;
  constructor(public payload: { equipment: Equipment }) {}
}
export class CreateEquipmentSuccess implements Action {
  readonly type = EquipmentActionTypes.CREATE_CONTACT_SUCCESS;
  constructor(public payload: { equipment: Equipment }) {}
}
export class CreateEquipmentError implements Action {
  readonly type = EquipmentActionTypes.CREATE_CONTACT_ERROR;
  constructor(public payload: any) {}
}

export class GetEquipment implements Action {
  readonly type = EquipmentActionTypes.GET_ONE;
  constructor(public payload: { _id: string }) {}
}
export class GetEquipmentSuccess implements Action {
  readonly type = EquipmentActionTypes.GET_ONE_SUCCESS;
  constructor(public payload: { equipment: Equipment }) {}
}
export class GetEquipmentError implements Action {
  readonly type = EquipmentActionTypes.GET_ONE_ERROR;
  constructor(public payload: any) {}
}

export class UpdateEquipment implements Action {
  readonly type = EquipmentActionTypes.UPDATE;
  constructor(public payload: { equipment: Update<Equipment> }) {}
}

export class UpdateEquipmentSuccess implements Action {
  readonly type = EquipmentActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { equipment: Update<Equipment> }) {}
}
export class UpdateEquipmentError implements Action {
  readonly type = EquipmentActionTypes.UPDATE_ERROR;
  constructor(public payload: any) {}
}

export class DeleteEquipment implements Action {
  readonly type = EquipmentActionTypes.DELETE;
  constructor(public payload: string) {}
}
export class DeleteEquipmentSuccess implements Action {
  readonly type = EquipmentActionTypes.DELETE_SUCCESS;
  constructor(public payload: any) {}
}
export class DeleteEquipmentError implements Action {
  readonly type = EquipmentActionTypes.DELETE_ERROR;
  constructor(public payload: Equipment) {}
}

export class DeleteManyEquipment implements Action {
  readonly type = EquipmentActionTypes.DELETE_MANY;
  constructor(public payload: string[]) {}
}
export class DeleteManyEquipmentSuccess implements Action {
  readonly type = EquipmentActionTypes.DELETE_MANY_SUCCESS;
  constructor(public payload: any) {}
}
export class DeleteManyEquipmentError implements Action {
  readonly type = EquipmentActionTypes.DELETE_MANY_ERROR;
  constructor(public payload: Equipment) {}
}

export type EquipmentActions =
  | SelectEquipment

  | GetEquipment
  | GetEquipmentSuccess
  | GetEquipmentError

  | UpdateEquipment
  | UpdateEquipmentSuccess
  | UpdateEquipmentError

  | GetEquipments
  | GetEquipmentsSuccess
  | GetEquipmentsError

  | CreateEquipment
  | CreateEquipmentSuccess
  | CreateEquipmentError

  | DeleteEquipment
  | DeleteEquipmentSuccess
  | DeleteEquipmentError

  | DeleteManyEquipment
  | DeleteManyEquipmentSuccess
  | DeleteManyEquipmentError;
