import { File } from '@app/shared/models/file.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  AvatarActions,
  AvatarActionTypes
} from '@app/core/store/actions/avatar.actions';

export interface State extends EntityState<File> {
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<File> = createEntityAdapter<File>({
  selectId: (avatar: File) => avatar._id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loading: false
});

export function reducer(state = initialState, action: AvatarActions): State {
  switch (action.type) {
    case AvatarActionTypes.GET_SUCCESS: {
      return adapter.addAll(action.payload.avatars, {
        ...state,
        loaded: true,
        loading: false
      });
    }

    case AvatarActionTypes.UPSERT_SUCCESS: {
      return adapter.upsertOne(action.payload.avatar, {
        ...state,
      });
    }

    case AvatarActionTypes.API_DOWNLOAD: {
      return adapter.upsertOne(
        { ...action.payload.avatar, _downloading: true },
        state
      );
    }
    case AvatarActionTypes.API_DOWNLOAD_SUCCESS: {
      return adapter.upsertOne(
        { ...action.payload.avatar, _downloading: false },
        state
      );
    }

    case AvatarActionTypes.DELETE_SUCCESS: {
      return adapter.removeOne(action.payload._id, state);
    }

    default: {
      return state;
    }
  }
}
export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const {
  // select the array of Avatar ids
  selectIds: selectAvatarIds,
  // select the dictionary of Avatar entities
  selectEntities: selectAvatarEntities,
  // select the array of avatars
  selectAll: selectAllAvatar,
  // select the total Avatar count
  selectTotal: selectAvatarTotal
} = adapter.getSelectors();
