import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';

import { Observable, fromEvent, Subscription } from 'rxjs';
import { map, delay } from 'rxjs/operators';

@Component({
  selector: 'app-btn-confirm',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button class="btn btn-link btn-sm inline-add" #cnf>
      <i *ngIf="!isConfirmed" class="fas fa-times"></i>
      <i
        *ngIf="isConfirmed"
        class="fas fa-exclamation-circle"
        style="color:#ff3860"
      ></i>
    </button>
  `,
  styles: [
    `
      .warning: {
        color: #ff3860;
      }
      .inline-add {
        padding: 0px;
        color: #757575;
      }
    `
  ]
})
export class BtnConfirmComponent implements OnInit, OnDestroy {
  @Output()
  confirm = new EventEmitter<any>();

  @ViewChild('cnf', {static: true})
  cnf;
  clicks$: Observable<any>;
  sub: Subscription;

  isConfirmed = false;
  timer;

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit() {
    this.sub = fromEvent(this.cnf.nativeElement, 'click')
      .pipe(
        map(list => {
          if (this.isConfirmed) {
            this.confirm.emit();
          }
          this.isConfirmed = true;
          this.ref.detectChanges();
          return list;
        }),
        delay(3000)
      )
      .subscribe(totalClicks => {
        this.isConfirmed = false;
        if (!this.ref['destroyed']) {
          this.ref.detectChanges();
        }
      });
  }

  click() {
    if (this.isConfirmed) {
      // alert('Delete will happen');
      this.confirm.emit();
    }
    this.isConfirmed = true;
    this.timer = setTimeout(() => {
      this.isConfirmed = false;
      this.ref.detectChanges();
    }, 3000);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.ref.detach();
    // clearTimeout(this.timer);
  }
}
