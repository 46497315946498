import { Action } from '@ngrx/store';
import { Toast } from '@app/core/models/toast.model';

export enum ToastActionTypes {
  ADD = '[NOTIFICATIONS] ADD',
  DISMISS = '[NOTIFICATIONS] DISMISS',
  OK = '[NOTIFICATIONS] OK',
  ERROR = '[NOTIFICATIONS] ERROR',
  INFO = '[NOTIFICATIONS] INFO',
  WARN = '[NOTIFICATIONS] WARN'
}

export class ToastsAdd implements Action {
  readonly type = ToastActionTypes.ADD;
  constructor(public payload: Toast) {}
}
export class ToastsOk implements Action {
  readonly type = ToastActionTypes.OK;
  constructor(public payload: string) {}
}
export class ToastsError implements Action {
  readonly type = ToastActionTypes.ERROR;
  constructor(public payload: string) {}
}
export class ToastsInfo implements Action {
  readonly type = ToastActionTypes.INFO;
  constructor(public payload: string) {}
}
export class ToastsWarn implements Action {
  readonly type = ToastActionTypes.WARN;
  constructor(public payload: string) {}
}

export class ToastsDismiss implements Action {
  readonly type = ToastActionTypes.DISMISS;
  constructor(public payload: Toast) {}
}

// Action Types
export type ToastsAction =
  | ToastsAdd
  | ToastsOk
  | ToastsError
  | ToastsInfo
  | ToastsWarn
  | ToastsDismiss;
