import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

import { Contact } from '@app/shared/models/contact.model';

@Component({
  selector: 'app-contact-card',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent implements OnInit {
  @Output()
  edit = new EventEmitter<Contact>();
  @Output()
  remove = new EventEmitter<Contact>();
  @Input()
  contact: Contact;
  @Input()
  set avatar(value) {
    this._avatar = value;
  }
  _avatar;
  get avatar() {
    return this._avatar;
  }

  constructor() {}

  ngOnInit() {}
}
