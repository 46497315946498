import {
  PaginationActions,
  PaginationActionTypes
} from '@app/core/store/actions/pagination.actions';

export interface State {
  count: number;
}

export const initialState: State = {
  count: 0
};

export function reducer(state = initialState, action: PaginationActions): State {
  switch (action.type) {
    case PaginationActionTypes.CHANGE_ITEMS_COUNT: {
      return {
        ...state,
        count: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const getItemsCount = (state: State) => state.count;
