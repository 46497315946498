import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Authenticate } from '../../models/authentication.model';

@Component({
  selector: 'app-login-form',
  template: `
    <div class="form-wrapper text-center">
      <form class="form-signin" [formGroup]="loginForm" (ngSubmit)="submit()">
        <h2>GWP TMS LOGIN</h2>
        <mat-form-field>
          <input
            matInput
            formControlName="email"
            placeholder="Email address"
            type="email"
            required
            autofocus
          />
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            formControlName="password"
            placeholder="Password"
            type="password"
            required
          />
        </mat-form-field>
        <div *ngIf="error" class="loginError">{{ error }}</div>
        <br />
        <button mat-flat-button color="primary" type="submit">Login</button>
        <p class="mt-5 mb-3 text-muted">&copy; 2017-2018</p>
      </form>
    </div>
  `,
  styles: [
    `
      form-wrapper {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #f5f5f5;
      }

      .form-signin {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
      }
      .form-signin .checkbox {
        font-weight: 400;
      }
      .form-signin .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
      }
      .form-signin .form-control:focus {
        z-index: 2;
      }
      .form-signin input[type='email'] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      .form-signin input[type='password'] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    `
  ]
})
export class LoginFormComponent implements OnInit {
  @Input()
  error: string | null;

  @Input()
  set disabled(isDisabled: boolean) {
    if (isDisabled) {
      this.loginForm.disable();
    } else {
      this.loginForm.enable();
    }
  }

  @Output()
  submitted = new EventEmitter<Authenticate>();

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });

  ngOnInit() {}

  submit() {
    const value: Authenticate = this.loginForm.value;

    if (this.loginForm.valid) {
      this.submitted.emit(value);
    }
  }
}
