import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core';

import { File } from '@app/shared/models/file.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { FilePreviewDialogComponent } from '../../popups/file-preview-dialog/file-preview-dialog.component';
@Component({
  selector: 'app-file-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.css']
})
export class FileListComponent implements OnInit {
  @Output()
  update: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  delete: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  download: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  changeSelectedFiles = new EventEmitter<File>();

  @Input()
  files: File[];

  constructor(public _DomSanitizer: DomSanitizer, public _MatDialog: MatDialog,) {}
  // private _MatDialog: MatDialog

  ngOnInit() {}

  changeSelected(changedFile: File, isSelected: boolean) {
    changedFile._selected = isSelected;
    this.changeSelectedFiles.emit(changedFile);
  }

  getInitials(ob) {
    if (ob.creator && ob.creator.name) {
      return (name || '').substr(0, 1).toUpperCase();
    }
    return '';
  }

  onPreview(file) {
    // this.download.emit({ file, preview: true });
    // OPEN MODAL WHEN FILE IS LOADED
    this._MatDialog
      .open(
        FilePreviewDialogComponent,
        {
          width: '100vh',
          data: file
        }
      )
      .afterClosed()
      .subscribe();
  }

  onDownload(file) {
    this.download.emit({ file, preview: false });
  }

  onUpdate(file, key, value, push = true) {
    this.update.emit({
      file: {
        ...file,
        [key]: value
      },
      push
    });
  }
}
