import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, switchMap, mergeMap, catchError } from 'rxjs/operators';

import * as EquipmentActions from '@app/core/store/actions/equipment.actions';
import { EquipmentService } from '@app/core/services/equipment.service';
import { ToastHelper } from '@app/core/services/toast.service';

@Injectable()
export class EquipmentEffects {
  @Effect()
  getEquipments$: Observable<Action> = this.actions$.pipe(
    ofType<EquipmentActions.GetEquipments>(EquipmentActions.EquipmentActionTypes.GET),
    switchMap(action => {
      return this._EquipmentService.getAll(action.payload.filters).pipe(
        map(resp => new EquipmentActions.GetEquipmentsSuccess(resp)),
        catchError(error => of(new EquipmentActions.GetEquipmentsError(error)))
      );
    })
    );

  @Effect()
  createEquipment$: Observable<Action> = this.actions$.pipe(
    ofType<EquipmentActions.CreateEquipment>(
      EquipmentActions.EquipmentActionTypes.CREATE_CONTACT
    ),
    mergeMap(action => {
      return this._EquipmentService.create(action.payload.equipment).pipe(
        map(equipment => {
          this.notify.ok('Equipment Added Successful!');
          return new EquipmentActions.CreateEquipmentSuccess({
            equipment
          });
        }),
        catchError(error => {
          this.notify.error(error.message);
          return of(new EquipmentActions.CreateEquipmentError(error));
        })
      );
    })
    );

  @Effect()
  getOneEquipment$: Observable<Action> = this.actions$.pipe(
    ofType<EquipmentActions.GetEquipment>(
      EquipmentActions.EquipmentActionTypes.GET_ONE
    ),
    mergeMap((action: EquipmentActions.GetEquipment) => {
      // console.log(action.payload);
      return this._EquipmentService.get(action.payload._id).pipe(
        map(resp => {
          return new EquipmentActions.GetEquipmentSuccess({
            equipment: resp
          });
        }),
        catchError(error => {
          this.notify.error(error);
          return of(new EquipmentActions.GetEquipmentsError(error));
        })
      );
    })
  );

  @Effect()
  updateEquipment$: Observable<Action> = this.actions$.pipe(
    ofType<EquipmentActions.UpdateEquipment>(
      EquipmentActions.EquipmentActionTypes.UPDATE
    ),
    mergeMap(action => {
      return this._EquipmentService.update(action.payload.equipment.changes).pipe(
        map(resp => {
          this.notify.ok('Equipment update was Successful!');
          return new EquipmentActions.UpdateEquipmentSuccess({
            equipment: { id: resp._id, changes: resp }
          });
        }),
        catchError(error => {
          this.notify.error(error);
          return of(
            new EquipmentActions.UpdateEquipmentError(
              action.payload.equipment.changes
            )
          );
        })
      );
    })
  );

  @Effect()
  deleteEquipment$: Observable<Action> = this.actions$.pipe(
    ofType<EquipmentActions.DeleteEquipment>(
      EquipmentActions.EquipmentActionTypes.DELETE
    ),
    mergeMap(action => {
      return this._EquipmentService.delete(action.payload).pipe(
        map((data: Response) => {
          this.notify.ok('Equipment Deleted Successful!');
          return new EquipmentActions.DeleteEquipmentSuccess(data);
        }),
        catchError(error => {
          this.notify.error(error);
          return of(new EquipmentActions.DeleteEquipmentError(error));
        })
      );
    })
  );

  @Effect()
  deleteManyEquipments$: Observable<Action> = this.actions$.pipe(
    ofType<EquipmentActions.DeleteManyEquipment>(
      EquipmentActions.EquipmentActionTypes.DELETE_MANY
    ),
    mergeMap(action => {
      return this._EquipmentService.deleteMany(action.payload).pipe(
        map((data: Response) => {
          this.notify.ok('Equipments Deleted Successful!');
          return new EquipmentActions.DeleteManyEquipmentSuccess(data);
        }),
        catchError(error => {
          this.notify.error(error);
          return of(new EquipmentActions.DeleteManyEquipmentError(error));
        })
      );
    })
  );

  constructor(
    private _EquipmentService: EquipmentService,
    private actions$: Actions,
    private notify: ToastHelper
  ) {}
}
