import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/core/store/reducers';
import * as fromRouter from '@app/core/store/selectors/router.selectors';
import * as fromContact from '@app/core/store/reducers/contact.reducers';
// Contacts State

export const selectContactState = createSelector(
  fromFeature.selectCoreState,
  (state: fromFeature.State) => state.contacts
);

export const getContactLoaded = createSelector(
  selectContactState,
  fromContact.getLoaded
);
export const getContactLoading = createSelector(
  selectContactState,
  fromContact.getLoading
);

export const selectContactIds = createSelector(
  selectContactState,
  fromContact.selectContactIds
);
export const selectContactEntities = createSelector(
  selectContactState,
  fromContact.selectContactEntities
);
export const selectAllContacts = createSelector(
  selectContactState,
  fromContact.selectAllContact
);
export const selectContactTotal = createSelector(
  selectContactState,
  fromContact.selectContactTotal
);
export const selectCurrentContactId = createSelector(
  selectContactState,
  fromContact.getSelectedId
);

export const selectCurrentContact = createSelector(
  selectContactEntities,
  selectCurrentContactId,
  (entities, contactId) => entities[contactId]
);

export const selectedContact = createSelector(
  selectContactEntities,
  fromRouter.getRouterState,
  (entities, router) => {
    return router.state && entities[router.state.params.contactId];
  }
);

export const selectSubContactsIds = createSelector(
  selectedContact,
  contact => {
    if (contact && contact.contacts) {
      return contact.contacts;
    }
  }
);

export const selectSubContacts = createSelector(
  selectSubContactsIds,
  selectAllContacts,
  (ids, contacts) => {
    if (ids && contacts) {
      return contacts.filter(c => ids.includes(c._id));
    }
  }
);

export const selectAccounts = createSelector(
  selectAllContacts,
  contacts => {
    return contacts.filter(c => c.isAccount);
  }
);
