import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/services/api.service';

@Injectable()
export class EquipmentService {
  path = `/equipment`;
  constructor(private apiService: ApiService) {}

  get(obId) {
    return this.apiService.get(this.path + `/${obId}`);
  }
  getAll(query) {
    return this.apiService.get(this.path, query);
  }
  create(ob) {
    return this.apiService.post(this.path, ob);
  }
  update(data) {
    Object.keys(data)
      .filter(k => k !== '_id' && k.startsWith('_'))
      .map(k => {
        console.log('delete', k);
        delete data[k];
      });
    return this.apiService.put(`${this.path}/${data._id}`, data);
  }
  delete(id: string) {
    return this.apiService.delete(`${this.path}/${id}`);
  }

  deleteMany(ids: string[]) {
    return this.apiService.post(`${this.path}/removeByIds`, {ids});
  }
}
