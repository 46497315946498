import { Contact } from '@app/shared/models/contact.model';

export class Notification {
  _id?: string;
  to: [string];
  from?: string;
  subject?: string;
  attachment?: [string];
  body: string;
  createdAt?: string;
  sentAt?: string;
  creator: {
    _id: string;
    name: string;
  };
  resource: string;
  resourceId: string;
  sendType: string;
}
