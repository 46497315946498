import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-not-found-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div>
      <h3>404: Not Found</h3>
      <div>
        <p>Hey! It looks like this page doesn't exist yet.</p>
      </div>
        <button primary routerLink="/">Take Me Home</button>
    </div>
  `,
  styles: [
    `
    :host {
      text-align: center;
    }
  `
  ]
})
export class NotFoundPageComponent {}
