import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/auth/store/reducers';
import * as fromLoginPage from '@app/auth/store/reducers/login-page.reducers';

export const selectLoginPageState = createSelector(
  fromFeature.featureSelectAuthState,
  (state: fromFeature.State) => state.loginPage
);

export const selectLoginPagePending = createSelector(
  selectLoginPageState,
  fromLoginPage.selectPending
);
export const selectLoginPageError = createSelector(
  selectLoginPageState,
  fromLoginPage.selectError
);
