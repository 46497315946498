import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAuthUser } from '@app/auth/store/selectors/auth.selectors';
import { State } from '@app/auth/store/reducers/auth.reducers';
import { takeUntil, map } from 'rxjs/operators';
import { Contact, LifeSubjectDestroyer } from '../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionService extends LifeSubjectDestroyer {
  public user$: Observable<Contact>;

  constructor(
    private store: Store<State>
  ) {
    super();
    this.user$ = this.store
      .select(selectAuthUser)
      .pipe(
        takeUntil(this.destroyed$)
      );
  }

  checkPermission(permission: string): Observable<boolean> {
    return this.user$
      .pipe(
        map(user => user && user.permissions && user.permissions.includes(permission))
      );
  }
}
