import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, switchMap, mergeMap, catchError, tap } from 'rxjs/operators';

import * as CoreActions from '@app/core/store/actions';
import { ContactService } from '@app/core/services/contact.service';
import { ToastHelper } from '@app/core/services/toast.service';

@Injectable()
export class ContactEffects {
  @Effect()
  getContacts$: Observable<Action> = this.actions$.pipe(
    ofType<CoreActions.GetContacts>(CoreActions.ContactActionTypes.GET),
    switchMap(action =>
      this._ContactService.getAll(action.payload.filters).pipe(
        mergeMap(resp => {
          const returnArr: any[] = [
            new CoreActions.GetContactsSuccess(resp.contacts || []),
            new CoreActions.ChangeItemsCount(Number(resp.countOfContacts))
          ];
          // if (resp.contacts.length) {
          //   returnArr.push(new CoreActions.GetAvatars({ resourceId: resp.contacts.map(contact => contact._id) }))
          // }
          return returnArr;
        }),
        catchError(error => of(new CoreActions.GetContactsError(error)))
      ))
  );

  @Effect()
  getContactsSuccess$: Observable<Action> = this.actions$.pipe(
    ofType<CoreActions.GetContactsSuccess>(CoreActions.ContactActionTypes.GET_SUCCESS),
    map(action => new CoreActions.GetAvatars({ resourceId: action.payload.map(contact => contact ? contact._id : null) }))
  );

  @Effect()
  createContact$: Observable<Action> = this.actions$.pipe(
    ofType<CoreActions.CreateContact>(
      CoreActions.ContactActionTypes.CREATE_CONTACT
    ),
    mergeMap(action =>
      this._ContactService.create(action.payload.contact).pipe(
        map(contact => {
          this.notify.ok('Contact Added Successful!');
          return new CoreActions.CreateContactSuccess({
            contact
          });
        }),
        catchError(error => {
          this.notify.error(error);
          return of(new CoreActions.CreateContactError(error));
        })
      ))
    );

  @Effect()
  createSubContact$: Observable<Action> = this.actions$.pipe(
    ofType<CoreActions.CreateSubContact>(
      CoreActions.ContactActionTypes.CREATE_SUB_CONTACT
    ),
    mergeMap(action =>
      // this.notify.ok('Contact Sub Added Successful!');
      this._ContactService
        .createSub(action.payload.parentId, action.payload.contact)
        .pipe(
          mergeMap(contact => [
            new CoreActions.CreateSubContactSuccess({
              parentId: action.payload.parentId,
              contact
            }),
            new CoreActions.GetContact({ _id: action.payload.parentId })
          ]),
          catchError(error => {
            this.notify.error(error);
            return of(new CoreActions.CreateSubContactError(error));
          })
        ))
    );

  @Effect()
  getOneContact$: Observable<Action> = this.actions$.pipe(
    ofType<CoreActions.GetContact>(
      CoreActions.ContactActionTypes.GET_ONE
    ),
    mergeMap((action: CoreActions.GetContact) =>
    this._ContactService.get(action.payload._id).pipe(
      map(resp => new CoreActions.UpsertContactSuccess({
        contact: resp
      })),
      catchError(error => {
        this.notify.error(error);
        return of(new CoreActions.UpsertContactError(action.payload));
      })
    ))
  );

  @Effect()
  getContactsByIds$ = this.actions$.pipe(
    ofType(CoreActions.ContactActionTypes.GET_BY_IDS),
    switchMap((action: CoreActions.GetContactsByIds) =>
      this._ContactService.getByIds(action.payload).pipe(
        mergeMap(resp => [
          new CoreActions.GetContactsByIdsSuccess({
            contacts: resp instanceof Array ? resp : [resp]
          }),
          new CoreActions.GetAvatars({ resourceId: resp.map(contact => contact._id) }),
        ]),
        catchError(error =>
          of(new CoreActions.GetContactsByIdsError(error))
        )
      )
    )
  );

  @Effect()
  updateContact$: Observable<Action> = this.actions$.pipe(
    ofType<CoreActions.UpdateContact>(
      CoreActions.ContactActionTypes.UPDATE
    ),
    tap(a => {
      console.count('Update Contact');
    }),
    // debounceTime(1000),
    mergeMap(action =>
      this._ContactService.update(action.payload.contact.changes).pipe(
        map(resp => {
          this.notify.ok('Contact update was Successful!');
          return new CoreActions.UpdateContactSuccess({
            contact: { id: resp._id, changes: resp }
          });
        }),
        catchError(error => {
          this.notify.error(error);
          return of(
            new CoreActions.UpdateContactError(
              action.payload.contact.changes
            )
          );
        })
      )
    )
  );

  @Effect()
  deleteContact$: Observable<Action> = this.actions$.pipe(
    ofType<CoreActions.DeleteContact>(
      CoreActions.ContactActionTypes.DELETE
    ),
    mergeMap(action =>
      this._ContactService.delete(action.payload).pipe(
        map((data: Response) => {
          this.notify.ok('Contact Deleted Successful!');
          return new CoreActions.DeleteContactSuccess(data);
        }),
        catchError(error => {
          this.notify.error(error);
          return of(new CoreActions.DeleteContactError(error));
        })
      ))
  );

  @Effect()
  deleteManyContact$: Observable<Action> = this.actions$.pipe(
    ofType<CoreActions.DeleteManyContact>(
      CoreActions.ContactActionTypes.DELETE_MANY
    ),
    mergeMap(action =>
      this._ContactService.deleteMany(action.payload).pipe(
        map((data: Response) => {
          this.notify.ok('Contact Deleted Successful!');
          return new CoreActions.DeleteContactSuccess(data);
        }),
        catchError(error => {
          this.notify.error(error);
          return of(new CoreActions.DeleteContactError(error));
        })
    ))
  );

  constructor(
    private _Router: Router,
    private _ContactService: ContactService,
    private actions$: Actions,
    private notify: ToastHelper
  ) {}
}
