import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromAuth from '@app/auth/store/reducers/auth.reducers';
import * as fromLoginPage from '@app/auth/store/reducers/login-page.reducers';

export const FeatureName = 'auth';
export interface State {
  auth: fromAuth.State;
  loginPage: fromLoginPage.State;
}

export const reducers: ActionReducerMap<State> = {
  auth: fromAuth.reducer,
  loginPage: fromLoginPage.reducer
};

// Feature Selector Name
export const featureSelectAuthState = createFeatureSelector<State>(FeatureName);
