import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Contact } from '@app/shared/models/contact.model';

export enum ContactActionTypes {
  SELECT = '[Contact] SELECT',

  GET = '[Contact] GET',
  GET_SUCCESS = '[Contact] GET_SUCCESS',
  GET_ERROR = '[Contact] GET_ERROR',

  GET_BY_IDS = '[Contact] GET_BY_IDS',
  GET_BY_IDS_SUCCESS = '[Contact] GET_BY_IDS_SUCCESS',
  GET_BY_IDS_ERROR = '[Contact] GET_BY_IDS_ERROR',

  CREATE_CONTACT = '[Contact] CREATE_CONTACT',
  CREATE_CONTACT_SUCCESS = '[Contact] CREATE_CONTACT_SUCCESS',
  CREATE_CONTACT_ERROR = '[Contact] CREATE_CONTACT_ERROR',

  CREATE_SUB_CONTACT = '[Contact] CREATE_SUB_CONTACT',
  CREATE_SUB_CONTACT_SUCCESS = '[Contact] CREATE_SUB_CONTACT_SUCCESS',
  CREATE_SUB_CONTACT_ERROR = '[Contact] CREATE_SUB_CONTACT_ERROR',

  GET_ONE = '[Contact] GET_ONE',
  GET_ONE_SUCCESS = '[Contact] GET_ONE_SUCCESS',
  GET_ONE_ERROR = '[Contact] GET_ONE_ERROR',

  UPDATE = '[Contact] UPDATE',
  UPDATE_SUCCESS = '[Contact] UPDATE_SUCCESS',
  UPDATE_ERROR = '[Contact] UPDATE_ERROR',

  UPSERT = '[Contact] UPSERT',
  UPSERT_SUCCESS = '[Contact] UPSERT_SUCCESS',
  UPSERT_ERROR = '[Contact] UPSERT_ERROR',

  UPSERT_MANY = '[Contact] UPSERT_MANY',

  DELETE = '[Contact] DELETE',
  DELETE_SUCCESS = '[Contact] DELETE_SUCCESS',
  DELETE_ERROR = '[Contact] DELETE_ERROR',

  DELETE_MANY = '[Contact] DELETE_MANY',
  DELETE_MANY_SUCCESS = '[Contact] DELETE_MANY_SUCCESS',
  DELETE_MANY_ERROR = '[Contact] DELETE_MANY_ERROR'
}

// Actions for Getting  Contacts
export class SelectContact implements Action {
  readonly type = ContactActionTypes.SELECT;
  constructor(public payload: string) {}
}
export class GetContacts implements Action {
  readonly type = ContactActionTypes.GET;
  constructor(public payload: { filters: object }) {}
}

export class GetContactsSuccess implements Action {
  readonly type = ContactActionTypes.GET_SUCCESS;
  constructor(public payload: Contact[]) {}
}
export class GetContactsError implements Action {
  readonly type = ContactActionTypes.GET_ERROR;
  constructor(public payload: any) {}
}

export class GetContactsByIds implements Action {
  readonly type = ContactActionTypes.GET_BY_IDS;
  constructor(public payload: string[]) {}
}

export class GetContactsByIdsSuccess implements Action {
  readonly type = ContactActionTypes.GET_BY_IDS_SUCCESS;
  constructor(public payload: { contacts: Contact[] }) {}
}
export class GetContactsByIdsError implements Action {
  readonly type = ContactActionTypes.GET_BY_IDS_ERROR;
  constructor(public payload: any) {}
}
// Action for Creating Contact
export class CreateContact implements Action {
  readonly type = ContactActionTypes.CREATE_CONTACT;
  constructor(public payload: { contact: Contact }) {}
}
export class CreateContactSuccess implements Action {
  readonly type = ContactActionTypes.CREATE_CONTACT_SUCCESS;
  constructor(public payload: { contact: Contact }) {}
}
export class CreateContactError implements Action {
  readonly type = ContactActionTypes.CREATE_CONTACT_ERROR;
  constructor(public payload: any) {}
}
export class CreateSubContact implements Action {
  readonly type = ContactActionTypes.CREATE_SUB_CONTACT;
  constructor(public payload: { parentId: string; contact: Contact }) {}
}
export class CreateSubContactSuccess implements Action {
  readonly type = ContactActionTypes.CREATE_SUB_CONTACT_SUCCESS;
  constructor(public payload: { parentId: string; contact: Contact }) {}
}
export class CreateSubContactError implements Action {
  readonly type = ContactActionTypes.CREATE_SUB_CONTACT_ERROR;
  constructor(public payload: any) {}
}

export class GetContact implements Action {
  readonly type = ContactActionTypes.GET_ONE;
  constructor(public payload: { _id: string }) {}
}
export class GetContactSuccess implements Action {
  readonly type = ContactActionTypes.GET_ONE_SUCCESS;
  constructor(public payload: { contact: Contact }) {}
}

export class GetContactError implements Action {
  readonly type = ContactActionTypes.GET_ONE_ERROR;
  constructor(public payload: any) {}
}
export class UpdateContact implements Action {
  readonly type = ContactActionTypes.UPDATE;
  constructor(public payload: { contact: Update<Contact> }) {}
}

export class UpdateContactSuccess implements Action {
  readonly type = ContactActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { contact: Update<Contact> }) {}
}

export class UpdateContactError implements Action {
  readonly type = ContactActionTypes.UPDATE_ERROR;
  constructor(public payload: any) {}
}

export class UpsertContact implements Action {
  readonly type = ContactActionTypes.UPSERT;
  constructor(public payload: { contact: Contact }) {}
}

export class UpsertContactSuccess implements Action {
  readonly type = ContactActionTypes.UPSERT_SUCCESS;
  constructor(public payload: { contact: Contact }) {}
}

export class UpsertContactError implements Action {
  readonly type = ContactActionTypes.UPSERT_ERROR;
  constructor(public payload: any) {}
}

export class UpsertContacts implements Action {
  readonly type = ContactActionTypes.UPSERT_MANY;
  constructor(public payload: Contact[]) {}
}

export class DeleteContact implements Action {
  readonly type = ContactActionTypes.DELETE;
  constructor(public payload: string) {}
}
export class DeleteContactSuccess implements Action {
  readonly type = ContactActionTypes.DELETE_SUCCESS;
  constructor(public payload: any) {}
}
export class DeleteContactError implements Action {
  readonly type = ContactActionTypes.DELETE_ERROR;
  constructor(public payload: Contact) {}
}

export class DeleteManyContact implements Action {
  readonly type = ContactActionTypes.DELETE_MANY;
  constructor(public payload: string[]) {}
}
export class DeleteManyContactSuccess implements Action {
  readonly type = ContactActionTypes.DELETE_MANY_SUCCESS;
  constructor(public payload: any) {}
}
export class DeleteManyContactError implements Action {
  readonly type = ContactActionTypes.DELETE_MANY_ERROR;
  constructor(public payload: Contact) {}
}

export type ContactActions =
  | SelectContact

  | GetContact
  | GetContactSuccess
  | GetContactError

  | GetContactsByIds
  | GetContactsByIdsSuccess
  | GetContactsByIdsError

  | UpdateContact
  | UpdateContactSuccess
  | UpdateContactError

  | UpsertContact
  | UpsertContactSuccess
  | UpsertContactError

  | UpsertContacts

  | GetContacts
  | GetContactsSuccess
  | GetContactsError

  | CreateContact
  | CreateContactSuccess
  | CreateContactError

  | CreateSubContact
  | CreateSubContactSuccess
  | CreateSubContactError

  | DeleteContact
  | DeleteContactSuccess
  | DeleteContactError

  | DeleteManyContact
  | DeleteManyContactSuccess
  | DeleteManyContactError;
