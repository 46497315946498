import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  map,
  switchMap,
  mergeMap,
  flatMap,
  catchError
} from 'rxjs/operators';

import * as AvatarActions from '@app/core/store/actions/avatar.actions';

import { FileService } from '@app/core/services/file.service';

@Injectable()
export class AvatarEffects {
  @Effect()
  getAvatars$ = this.actions$.pipe(
    ofType<AvatarActions.GetAvatar>(AvatarActions.AvatarActionTypes.GET),
    switchMap(action => {
      return this._FileService.getAll({ ...action.payload, resource: 'avatars' }).pipe(
        map(
          resp =>
            new AvatarActions.GetAvatarsSuccess({
              avatars: resp
            })
        ),
        catchError(error => of(new AvatarActions.GetAvatarsError(error)))
      );
    })
  );

  @Effect()
  deleteAvatar$ = this.actions$.pipe(
    ofType<AvatarActions.DeleteAvatar>(AvatarActions.AvatarActionTypes.DELETE),
    mergeMap(action => {
      return this._FileService.delete(action.payload).pipe(
        switchMap((data: Response) => [
          new AvatarActions.DeleteAvatarSuccess(action.payload)
        ]),
        catchError(error => {
          return of(new AvatarActions.DeleteAvatarError(error));
        })
      );
    })
  );

  @Effect()
  downloadAvatar$ = this.actions$.pipe(
    ofType<AvatarActions.ApiDownloadAvatar>(
      AvatarActions.AvatarActionTypes.API_DOWNLOAD
    ),
    mergeMap(action => {
      // Passing Name from component, Name is not sent from server.
      const name = action.payload.avatar.name;
      return this._FileService.download(action.payload.avatar).pipe(
        map(resp => {
          const avatar = this._FileService.getBlobURL({
            ...resp,
            name,
            uploadName: action.payload.avatar.uploadName
          });

          return { ...action.payload.avatar, ...avatar };
        }),
        map(resp => new AvatarActions.ApiDownloadAvatarSuccess({ avatar: resp })),
        catchError(error => {
          return of(new AvatarActions.ApiDownloadAvatarError(error));
        })
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _FileService: FileService
  ) {}
}
