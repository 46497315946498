import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Order } from '@app/shared/models';

export enum OrderActionTypes {
  SELECT = '[Order] SELECT',

  GET = '[Order] GET',
  GET_SUCCESS = '[Order] GET_SUCCESS',
  GET_ERROR = '[Order] GET_ERROR',

  CREATE_ORDER = '[Order] CREATE_ORDER',
  CREATE_ORDER_SUCCESS = '[Order] CREATE_ORDER_SUCCESS',
  CREATE_ORDER_ERROR = '[Order] CREATE_ORDER_ERROR',

  GET_ONE = '[Order] GET_ONE',
  GET_ONE_SUCCESS = '[Order] GET_ONE_SUCCESS',
  GET_ONE_ERROR = '[Order] GET_ONE_ERROR',

  UPDATE = '[Order] UPDATE',
  UPDATE_SUCCESS = '[Order] UPDATE_SUCCESS',
  UPDATE_ERROR = '[Order] UPDATE_ERROR',

  UPSERT = '[Order] UPSERT',
  UPSERT_SUCCESS = '[Order] UPSERT_SUCCESS',
  UPSERT_ERROR = '[Order] UPSERT_ERROR',

  DELETE = '[Order] DELETE',
  DELETE_SUCCESS = '[Order] DELETE_SUCCESS',
  DELETE_ERROR = '[Order] DELETE_ERROR',

  DELETE_MANY = '[Order] DELETE_MANY',
  DELETE_MANY_SUCCESS = '[Order] DELETE_MANY_SUCCESS',
  DELETE_MANY_ERROR = '[Order] DELETE_MANY_ERROR'
}

// Actions for Getting  Orders
export class SelectOrder implements Action {
  readonly type = OrderActionTypes.SELECT;
  constructor(public payload: string) {}
}
export class GetOrders implements Action {
  readonly type = OrderActionTypes.GET;
  constructor(public payload: { filters: object }) {}
}

export class GetOrdersSuccess implements Action {
  readonly type = OrderActionTypes.GET_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: Order[]) {}
  constructor(public payload: Order[]) {}
}
export class GetOrdersError implements Action {
  readonly type = OrderActionTypes.GET_ERROR;
  constructor(public payload: any) {}
}
// Action for Creating Order
export class CreateOrder implements Action {
  readonly type = OrderActionTypes.CREATE_ORDER;
  constructor(public payload: { order: Order }) {}
}
export class CreateOrderSuccess implements Action {
  readonly type = OrderActionTypes.CREATE_ORDER_SUCCESS;
  constructor(public payload: { order: Order }) {}
}
export class CreateOrderError implements Action {
  readonly type = OrderActionTypes.CREATE_ORDER_ERROR;
  constructor(public payload: any) {}
}
export class GetOrder implements Action {
  readonly type = OrderActionTypes.GET_ONE;
  constructor(public payload: { _id: string }) {}
}
export class GetOrderSuccess implements Action {
  readonly type = OrderActionTypes.GET_ONE_SUCCESS;
  constructor(public payload: { order: Order }) {}
}

export class GetOrderError implements Action {
  readonly type = OrderActionTypes.GET_ONE_ERROR;
  constructor(public payload: any) {}
}
export class UpdateOrder implements Action {
  readonly type = OrderActionTypes.UPDATE;
  constructor(public payload: { order: Update<Order> }) {}
}

export class UpdateOrderSuccess implements Action {
  readonly type = OrderActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { order: Update<Order> }) {}
}

export class UpdateOrderError implements Action {
  readonly type = OrderActionTypes.UPDATE_ERROR;
  constructor(public payload: any) {}
}

export class UpsertOrder implements Action {
  readonly type = OrderActionTypes.UPSERT;
  constructor(public payload: { order: Order }) {}
}

export class UpsertOrderSuccess implements Action {
  readonly type = OrderActionTypes.UPSERT_SUCCESS;
  constructor(public payload: { order: Order }) {}
}

export class UpsertOrderError implements Action {
  readonly type = OrderActionTypes.UPSERT_ERROR;
  constructor(public payload: any) {}
}

export class DeleteOrder implements Action {
  readonly type = OrderActionTypes.DELETE;
  constructor(public payload: Order) {}
}
export class DeleteOrderSuccess implements Action {
  readonly type = OrderActionTypes.DELETE_SUCCESS;
  constructor(public payload: any) {}
}
export class DeleteOrderError implements Action {
  readonly type = OrderActionTypes.DELETE_ERROR;
  constructor(public payload: Order) {}
}

export class DeleteManyOrder implements Action {
  readonly type = OrderActionTypes.DELETE_MANY;
  constructor(public payload: string[]) {}
}
export class DeleteManyOrderSuccess implements Action {
  readonly type = OrderActionTypes.DELETE_MANY_SUCCESS;
  constructor(public payload: any) {}
}
export class DeleteManyOrderError implements Action {
  readonly type = OrderActionTypes.DELETE_MANY_ERROR;
  constructor(public payload: Order) {}
}

export type OrderActions =
  | SelectOrder

  | GetOrder
  | GetOrderSuccess
  | GetOrderError

  | UpdateOrder
  | UpdateOrderSuccess
  | UpdateOrderError

  | UpsertOrder
  | UpsertOrderSuccess
  | UpsertOrderError

  | GetOrders
  | GetOrdersSuccess
  | GetOrdersError

  | CreateOrder
  | CreateOrderSuccess
  | CreateOrderError

  | DeleteOrder
  | DeleteOrderSuccess
  | DeleteOrderError

  | DeleteManyOrder
  | DeleteManyOrderSuccess
  | DeleteManyOrderSuccess;
