import { Injectable } from '@angular/core';

const url = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAzTr50QlWvStPTEX7EURDBGlw60kfytjQ&language=en&libraries=places';
@Injectable({
  providedIn: 'root'
})
export class GoogleMapService {
  geoCoder: google.maps.Geocoder;
  direction: google.maps.DirectionsService;

  directionsRenderers: google.maps.DirectionsRenderer[] = [];
  renderOptions = {
    draggable: false,
    getPolyline: true,
    suppressMarkers: true,
    preserveViewport: true
  };

  loadAPI: Promise<void>;
  constructor() {
    this.loadAPI = new Promise((resolve, reject) => {
      if (typeof google !== 'object') {
        this.loadScript().then(_ => {
          console.log('gmapi loaded');
          this.geoCoder = new google.maps.Geocoder();
          this.direction = new google.maps.DirectionsService();
          resolve();
        }, error => {
          console.log('gmapi not loaded!!!');
          console.log(error);
          reject();
        });
      } else {
        resolve();
      }
    });
  }

  loadScript() {
    return new Promise((resolve, reject) => {
      const googleMapsScript = document.createElement('script');
      googleMapsScript.src = url;
      googleMapsScript.type = 'text/javascript';
      googleMapsScript.addEventListener('load', () => resolve(googleMapsScript), false);
      googleMapsScript.addEventListener('error', () => reject(googleMapsScript), false);
      document.getElementsByTagName('head')[0].appendChild(googleMapsScript);
    });
  }
}
