export const environment = {
  production: true,
  apiUrl: 'https://api.westlandfreight.com/api',
  appUrl: 'https://app.westlandfreight.com/',
  firebase: {
    apiKey: 'AIzaSyAzTr50QlWvStPTEX7EURDBGlw60kfytjQ',
    authDomain: 'wgl-loadboard.firebaseapp.com',
    databaseURL: 'https://wgl-loadboard.firebaseio.com',
    projectId: 'wgl-loadboard',
    storageBucket: 'wgl-loadboard.appspot.com',
    messagingSenderId: '134897908143'
  }
};
