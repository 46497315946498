import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/core/store/reducers';
import * as fromRouter from '@app/core/store/selectors/router.selectors';
import * as fromEquipment from '@app/core/store/reducers/equipment.reducers';
// Equipments State

export const selectEquipmentState = createSelector(
  fromFeature.selectCoreState,
  (state: fromFeature.State) => state.equipments
);

export const getEquipmentLoaded = createSelector(
  selectEquipmentState,
  fromEquipment.getLoaded
);
export const getEquipmentLoading = createSelector(
  selectEquipmentState,
  fromEquipment.getLoading
);

export const selectEquipmentIds = createSelector(
  selectEquipmentState,
  fromEquipment.selectEquipmentIds
);
export const selectEquipmentEntities = createSelector(
  selectEquipmentState,
  fromEquipment.selectEquipmentEntities
);
export const selectAllEquipments = createSelector(
  selectEquipmentState,
  fromEquipment.selectAllEquipment
);
export const selectEquipmentTotal = createSelector(
  selectEquipmentState,
  fromEquipment.selectEquipmentTotal
);
export const selectCurrentEquipmentId = createSelector(
  selectEquipmentState,
  fromEquipment.getSelectedId
);

export const selectCurrentEquipment = createSelector(
  selectEquipmentEntities,
  selectCurrentEquipmentId,
  (entities, equipmentId) => entities[equipmentId]
);

export const selectedEquipment = createSelector(
  selectEquipmentEntities,
  fromRouter.getRouterState,
  (entities, router) => {
    return router.state && entities[router.state.params.equipmentId];
  }
);

