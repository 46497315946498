import { NgModule } from '@angular/core';

import { MyCurrencyPipe } from '@app/shared/pipes/my-currency.pipe';
import { UtcDatePipe } from '@app/shared/pipes/utcDate.pipe';
import { TitleCasePipe } from '@app/shared/pipes/title-case.pipe';
import { FindPipe } from '@app/shared/pipes/find.pipe';
import { KeysPipe } from '@app/shared/pipes/keys.pipe';

export const PIPES = [
  MyCurrencyPipe,
  UtcDatePipe,
  TitleCasePipe,
  FindPipe,
  KeysPipe
];

@NgModule({
  declarations: PIPES,
  exports: PIPES
})
export class PipesModule {}
