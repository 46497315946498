export interface Phone {
  _id?: string;
  value?: string;
  type: string;
  notify?: boolean;
  createdAt?: string;
}

export function genMockPhone(): Phone {
  return {
    value: '',
    type: 'Direct',
    notify: false
  };
}
