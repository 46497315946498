import { Action } from '@ngrx/store';
import {
  AuthActions,
  AuthActionTypes
} from '@app/auth/store/actions/auth.actions';
import { Contact } from '@app/shared/models/contact.model';

export interface State {
  user: Contact | null;
}

export const initialState: State = {
  user: null
};

export function reducer(state = initialState, action: AuthActions): State {
  switch (action.type) {
    case AuthActionTypes.LoginSuccess:
      return { ...state, user: action.payload.user };

    case AuthActionTypes.LogoutConfirmed:
      return initialState;

    default:
      return state;
  }
}

export const selectUser = (state: State) => state.user;
