import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FeatureName, reducers, effects } from '@app/auth/store';

import { MaterialModule } from '@app/material';

import { AuthService } from '@app/auth/services/auth.service';
import { LoginPageComponent } from '@app/auth/components/login-page/login-page.component';
import { AuthRoutingModule } from '@app/auth/auth-routing.module';
import { LoginFormComponent } from '@app/auth/components/login-form/login-form.component';
import { LogoutPromptComponent } from '@app/auth/components/logout-prompt/logout-prompt.component';
import { LoginPromptComponent } from '@app/auth/components/login-prompt/login-prompt.component';
import { UserHomeComponent } from '@app/auth/components/user-home/user-home.component';

@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    StoreModule.forFeature(FeatureName, reducers),
    EffectsModule.forFeature(effects),
    MaterialModule
  ],
  declarations: [
    LoginPageComponent,
    LoginFormComponent,
    LoginPromptComponent,
    LogoutPromptComponent,
    UserHomeComponent
  ],
  entryComponents: [LoginPromptComponent, LogoutPromptComponent]
})
export class AuthModule {}
