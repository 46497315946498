import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromAuthStore from '@app/auth/store';

import { Contact } from '@app/shared/models';

import { ToastHelper } from '@app/core/services/toast.service';
import { ApiService } from '@app/shared/services/api.service';
declare var require: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [ToastHelper]
})
export class AppComponent implements OnInit {
  currentUser$: Observable<Contact>;
  loading = 0;
  appVersion = require('../../../../../package.json').version;

  constructor(
    private store: Store<fromAuthStore.State>,
    apiService: ApiService
  ) {
    apiService.loadSubject.subscribe(loadNum => setTimeout(_ => (this.loading = loadNum), 0));
  }

  ngOnInit() {
    console.log('[App Version] ', this.appVersion);
    this.currentUser$ = this.store.select(fromAuthStore.selectAuthUser);
  }
  onLogout() {
    this.store.dispatch(new fromAuthStore.Logout());
  }
}
