import { Order } from '@app/shared/models/order.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  OrderActions,
  OrderActionTypes
} from '@app/core/store/actions/order.actions';

export interface State extends EntityState<Order> {
  selectId: string | null;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<Order> = createEntityAdapter<Order>({
  selectId: (order: Order) => order._id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectId: null,
  loaded: false,
  loading: false
});

export function reducer(state = initialState, action: OrderActions): State {
  switch (action.type) {
    case OrderActionTypes.GET_SUCCESS: {
      return adapter.addAll(action.payload, {
        ...state,
        selectId: state.selectId,
        loaded: true,
        loading: false
      });
    }

    case OrderActionTypes.GET_ONE_SUCCESS: {
      return adapter.addOne(action.payload.order, {
        ...state,
        selectId: state.selectId
      });
    }

    case OrderActionTypes.SELECT: {
      return {
        ...state,
        selectId: action.payload
      };
    }

    // case OrderActionTypes.ADD_ONE: {
    //   // return adapter.addOne(action.payload.order, state);
    //   // Nothing to change to the store at this point. An effect CREATE is also triggered and will subsequently fire a CREATE_DONE action.
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // }

    case OrderActionTypes.CREATE_ORDER_SUCCESS: {
      return adapter.addOne(action.payload.order, { ...state, loaded: true });
    }

    // case OrderActionTypes.UPSERT_SUCCESS: {
    //   return adapter.upsertOne(action.payload.order, {
    //     ...state,
    //     selectId: action.payload.order.id
    //   });
    // }

    // case OrderActionTypes.UPSERT_MANY: {
    // return adapter.upsertMany(action.payload.orders, state);
    // }

    case OrderActionTypes.UPDATE: {
      return adapter.updateOne(action.payload.order, state);
    }

    case OrderActionTypes.UPDATE_SUCCESS: {
      return adapter.updateOne(action.payload.order, state);
    }

    case OrderActionTypes.DELETE_MANY: {
      return adapter.removeMany(action.payload, state);
    }

    // case OrderActionTypes.UPDATE_MANY: {
    //   return adapter.updateMany(action.payload.orders, state);
    // }

    // case OrderActionTypes.DELETE: {
    //   return adapter.removeOne(action.payload.id, state);
    // }

    // case OrderActionTypes.DELETE_MANY: {
    //   return adapter.removeMany(action.payload.ids, state);
    // }

    // case OrderActionTypes.DELETE_MANY: {
    //   return adapter.removeAll({ ...state, selectedOrderId: null });
    // }

    default: {
      return state;
    }
  }
}
export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getSelectedId = (state: State) => state.selectId;
export const {
  // select the array of Order ids
  selectIds: selectOrderIds,
  // select the dictionary of Order entities
  selectEntities: selectOrderEntities,
  // select the array of orders
  selectAll: selectAllOrder,
  // select the total Order count
  selectTotal: selectOrderTotal
} = adapter.getSelectors();
