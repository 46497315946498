import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { MailNotify } from '@app/shared/models/emailNotify.model';

export enum MailNotifyActionTypes {
  SELECT = '[MailNotify] SELECT',

  GET = '[MailNotify] GET',
  GET_SUCCESS = '[MailNotify] GET_SUCCESS',
  GET_ERROR = '[MailNotify] GET_ERROR',

  CREATE_CONTACT = '[MailNotify] CREATE_CONTACT',
  CREATE_CONTACT_SUCCESS = '[MailNotify] CREATE_CONTACT_SUCCESS',
  CREATE_CONTACT_ERROR = '[MailNotify] CREATE_CONTACT_ERROR',

  GET_ONE = '[MailNotify] GET_ONE',
  GET_ONE_SUCCESS = '[MailNotify] GET_ONE_SUCCESS',
  GET_ONE_ERROR = '[MailNotify] GET_ONE_ERROR',

  UPDATE = '[MailNotify] UPDATE',
  UPDATE_SUCCESS = '[MailNotify] UPDATE_SUCCESS',
  UPDATE_ERROR = '[MailNotify] UPDATE_ERROR',

  DELETE = '[MailNotify] DELETE',
  DELETE_SUCCESS = '[MailNotify] DELETE_SUCCESS',
  DELETE_ERROR = '[MailNotify] DELETE_ERROR',
}

// Actions for Getting  EmailNotifies
export class SelectMailNotify implements Action {
  readonly type = MailNotifyActionTypes.SELECT;
  constructor(public payload: string) {}
}

export class GetEmailNotifies implements Action {
  readonly type = MailNotifyActionTypes.GET;
  constructor(public payload: { resource: string, resourceId: string }) {}
}
export class GetEmailNotifiesSuccess implements Action {
  readonly type = MailNotifyActionTypes.GET_SUCCESS;
  constructor(public payload: MailNotify[]) {}
}
export class GetEmailNotifiesError implements Action {
  readonly type = MailNotifyActionTypes.GET_ERROR;
  constructor(public payload: any) {}
}

// Action for Creating MailNotify
export class CreateMailNotify implements Action {
  readonly type = MailNotifyActionTypes.CREATE_CONTACT;
  constructor(public payload: { emailNotify: MailNotify }) {}
}
export class CreateMailNotifySuccess implements Action {
  readonly type = MailNotifyActionTypes.CREATE_CONTACT_SUCCESS;
  constructor(public payload: { emailNotify: MailNotify }) {}
}
export class CreateMailNotifyError implements Action {
  readonly type = MailNotifyActionTypes.CREATE_CONTACT_ERROR;
  constructor(public payload: any) {}
}

export class GetMailNotify implements Action {
  readonly type = MailNotifyActionTypes.GET_ONE;
  constructor(public payload: { _id: string }) {}
}
export class GetMailNotifySuccess implements Action {
  readonly type = MailNotifyActionTypes.GET_ONE_SUCCESS;
  constructor(public payload: { emailNotify: MailNotify }) {}
}
export class GetMailNotifyError implements Action {
  readonly type = MailNotifyActionTypes.GET_ONE_ERROR;
  constructor(public payload: any) {}
}

export class UpdateMailNotify implements Action {
  readonly type = MailNotifyActionTypes.UPDATE;
  constructor(public payload: { emailNotify: Update<MailNotify> }) {}
}

export class UpdateMailNotifySuccess implements Action {
  readonly type = MailNotifyActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { emailNotify: Update<MailNotify> }) {}
}
export class UpdateMailNotifyError implements Action {
  readonly type = MailNotifyActionTypes.UPDATE_ERROR;
  constructor(public payload: any) {}
}

export class DeleteMailNotify implements Action {
  readonly type = MailNotifyActionTypes.DELETE;
  constructor(public payload: string) {}
}
export class DeleteMailNotifySuccess implements Action {
  readonly type = MailNotifyActionTypes.DELETE_SUCCESS;
  constructor(public payload: any) {}
}
export class DeleteMailNotifyError implements Action {
  readonly type = MailNotifyActionTypes.DELETE_ERROR;
  constructor(public payload: MailNotify) {}
}

export type MailNotifyActions =
  | SelectMailNotify

  | GetMailNotify
  | GetMailNotifySuccess
  | GetMailNotifyError

  | UpdateMailNotify
  | UpdateMailNotifySuccess
  | UpdateMailNotifyError

  | GetEmailNotifies
  | GetEmailNotifiesSuccess
  | GetEmailNotifiesError

  | CreateMailNotify
  | CreateMailNotifySuccess
  | CreateMailNotifyError

  | DeleteMailNotify
  | DeleteMailNotifySuccess
  | DeleteMailNotifyError;
