import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { Action } from "@ngrx/store";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { map, switchMap, mergeMap, catchError, tap, debounceTime, distinctUntilChanged } from "rxjs/operators";

import * as OrderActions from "@app/core/store/actions/order.actions";
import * as ContactActions from "@app/core/store/actions/contact.actions";
import * as PaginationActions from "@app/core/store/actions/pagination.actions";

import { OrderService } from "@app/core/services/order.service";
import { ToastHelper } from "@app/core/services/toast.service";

@Injectable()
export class OrderEffects {
  @Effect()
  getOrders$: Observable<Action> = this.actions$.pipe(
    ofType<OrderActions.GetOrders>(OrderActions.OrderActionTypes.GET),
    switchMap(action => {
      return this._OrderService.getAll(action.payload.filters).pipe(
        mergeMap(resp => [
          new OrderActions.GetOrdersSuccess(
            resp.orders || []
          ),
          new ContactActions.UpsertContacts(
            resp.orders
              .map(order => Object.values(order._contacts).flat())
              .flat()
              .filter(i => i) || []
          ),
          new PaginationActions.ChangeItemsCount(Number(resp.countOfOrders))
        ]),
        catchError(error => of(new OrderActions.GetOrdersError(error)))
      );
    })
  );

  @Effect()
  createOrder$: Observable<Action> = this.actions$.pipe(
    ofType<OrderActions.CreateOrder>(
      OrderActions.OrderActionTypes.CREATE_ORDER
    ),
    mergeMap(action => {
      return this._OrderService.create(action.payload.order).pipe(
        map(order => {
          this.notify.ok("Order Added Successful!");
          return new OrderActions.CreateOrderSuccess({
            order
          });
        }),
        catchError(error => {
          this.notify.error(error);
          return of(new OrderActions.CreateOrderError(error));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  createOrderSuccess$ = this.actions$.pipe(
    ofType<OrderActions.CreateOrderSuccess>(OrderActions.OrderActionTypes.CREATE_ORDER_SUCCESS),
    tap(resp => this._Router.navigate(['/loads/' + resp.payload.order._id]))
  );

  @Effect()
  getOrder$: Observable<Action> = this.actions$.pipe(
    ofType<OrderActions.GetOrder>(OrderActions.OrderActionTypes.GET_ONE),
    mergeMap((action: OrderActions.GetOrder) => {
      return this._OrderService.get(action.payload._id).pipe(
        mergeMap(order => [
          new OrderActions.GetOrderSuccess({ order }),
          new ContactActions.GetContactsSuccess(
            Object.values(order._contacts)
              .flat()
              .filter(i => i) || []
          )
        ]),
        catchError(error => {
          this.notify.error(error);
          return of(new OrderActions.GetOrderError(error));
        })
      );
    })
  );

  @Effect()
  updateOrder$: Observable<Action> = this.actions$.pipe(
    ofType<OrderActions.UpdateOrder>(OrderActions.OrderActionTypes.UPDATE),
    tap(a => {
      console.count("Update Order");
    }),
    debounceTime(1500),
    distinctUntilChanged(),
    mergeMap(action => {
      return this._OrderService.update(action.payload.order.changes).pipe(
        map(resp => {
          this.notify.ok("Order update was Successful!");
          return new OrderActions.UpdateOrderSuccess({
            order: { id: resp._id, changes: resp }
          });
        }),
        catchError(error => {
          this.notify.error(error);
          return of(
            new OrderActions.UpdateOrderError(action.payload.order.changes)
          );
        })
      );
    })
  );

  @Effect()
  deleteOrder$: Observable<Action> = this.actions$.pipe(
    ofType<OrderActions.DeleteOrder>(OrderActions.OrderActionTypes.DELETE),
    mergeMap(action => {
      return this._OrderService.delete(action.payload._id).pipe(
        map((data: Response) => {
          this.notify.ok("Order Deleted Successful!");
          this._Router.navigate(["/orders"]);
          return new OrderActions.DeleteOrderSuccess(data);
        }),
        catchError(error => {
          this.notify.error(error);
          return of(new OrderActions.DeleteOrderError(action.payload));
        })
      );
    })
  );

  @Effect()
  deleteManyOrder$: Observable<Action> = this.actions$.pipe(
    ofType<OrderActions.DeleteManyOrder>(
      OrderActions.OrderActionTypes.DELETE_MANY
    ),
    mergeMap(action => {
      return this._OrderService.deleteMany(action.payload).pipe(
        map((data: Response) => {
          this.notify.ok("Contact Deleted Successful!");
          return new OrderActions.DeleteManyOrderSuccess(data);
        }),
        catchError(error => {
          this.notify.error(error);
          return of(new OrderActions.DeleteManyOrderError(error));
        })
      );
    })
  );

  // @Effect({ dispatch: false })
  // downloadOrderFile$ = this.actions$
  //   .ofType<OrderActions.GetOrderContractSuccess>(
  //     OrderActions.OrderActionTypes.GET_CONTRACT_SUCCESS,
  //     OrderActions.OrderActionTypes.GET_BOL_SUCCESS
  //   )
  //   .pipe(
  //     tap(action => {
  //       const blob = new Blob([action.payload.data], {
  //         type: 'application/pdf'
  //       });
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       // a.style = 'display: none';
  //       a.href = url;
  //       a.download = action.payload.name;
  //       document.body.appendChild(a);
  //       a.click();
  //       setTimeout(function() {
  //         document.body.removeChild(a);
  //         window.URL.revokeObjectURL(url);
  //       }, 100);
  //     })
  //   );

  // @Effect({ dispatch: false })
  // connectOrdersCounter$: Observable<Action> = this.actions$
  //   .ofType<OrderActions.GetOrderCount>(OrderActions.OrderActionTypes.GET_COUNT)
  //   .pipe(tap(() => this._OrderService.triggerCountListen()));

  // @Effect()
  // listenOrderCouter$: Observable<
  //   Action
  // > = this._OrderService.listenToCount().pipe(
  //   distinctUntilChanged((o, n) => {
  //     return o.data['ALL'] === n.data['ALL'];
  //   }),
  //   switchMap(resp => {
  //     // console.log('listenOrderCouter', resp);
  //     return Observable.of(new OrderActions.GetOrderCountSuccess(resp.data));
  //   })
  // );

  constructor(
    private _Router: Router,
    private _OrderService: OrderService,
    private actions$: Actions,
    private notify: ToastHelper
  ) {}
}
