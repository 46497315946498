import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/core/store/reducers';
import * as fromToasts from '@app/core/store/reducers/toast.reducer';

// Toasts State
export const selectToastsState = createSelector(
  fromFeature.selectCoreState,
  (state: fromFeature.State) => state.toasts
);

export const getAllToast = createSelector(
  selectToastsState,
  fromToasts.getToastEntities
);
