import * as fromFiles from '@app/core/store/actions/file.actions';

import { File } from '@app/shared/models/file.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  FileActions,
  FileActionTypes
} from '@app/core/store/actions/file.actions';

export interface State extends EntityState<File> {
  selectId: string | null;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<File> = createEntityAdapter<File>({
  selectId: (file: File) => file._id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectId: null,
  loaded: false,
  loading: false
});

export function reducer(state = initialState, action: FileActions): State {
  switch (action.type) {
    case FileActionTypes.GET_SUCCESS: {
      return adapter.addAll(action.payload.files, {
        ...state,
        selectId: state.selectId,
        loaded: true,
        loading: false
      });
    }

    case FileActionTypes.ADD_ONE: {
      // return adapter.addOne(action.payload.file, state);
      // Nothing to change to the store at this point. An effect CREATE is also triggered and will subsequently fire a CREATE_DONE action.
      return {
        ...state,
        loading: true
      };
    }

    case FileActionTypes.ADD_ONE_SUCCESS: {
      return adapter.addOne(action.payload.file, { ...state, loaded: true });
    }

    case FileActionTypes.UPDATE: {
      return adapter.updateOne(action.payload.file, state);
    }

    case FileActionTypes.UPDATE_SUCCESS: {
      return adapter.updateOne(action.payload.file, state);
    }

    case FileActionTypes.SELECT: {
      return adapter.updateOne(action.payload.file, state);
    }

    case FileActionTypes.UPSERT_SUCCESS: {
      return adapter.upsertOne(action.payload.file, {
        ...state,
        selectId: action.payload.file._id
      });
    }

    case FileActionTypes.API_DOWNLOAD: {
      return adapter.upsertOne(
        { ...action.payload.file, _downloading: true },
        state
      );
    }
    case FileActionTypes.API_DOWNLOAD_SUCCESS: {
      return adapter.upsertOne(
        { ...action.payload.file, _downloading: false },
        state
      );
    }

    case FileActionTypes.DELETE_SUCCESS: {
      return adapter.removeOne(action.payload._id, state);
    }

    default: {
      return state;
    }
  }
}
export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getSelectedId = (state: State) => state.selectId;
export const {
  // select the array of File ids
  selectIds: selectFileIds,
  // select the dictionary of File entities
  selectEntities: selectFileEntities,
  // select the array of files
  selectAll: selectAllFile,
  // select the total File count
  selectTotal: selectFileTotal
} = adapter.getSelectors();
