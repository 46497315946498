import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { File } from '@app/shared/models/file.model';

export enum FileActionTypes {
  GET = '[File] GET',
  GET_SUCCESS = '[File] GET_SUCCESS',
  GET_ERROR = '[File] GET_ERROR',
  ADD_ONE = '[File] ADD_ONE',
  ADD_ONE_SUCCESS = '[File] ADD_ONE_SUCCESS',
  ADD_ONE_ERROR = '[File] ADD_ONE_ERROR',
  GET_ONE = '[File] GET_ONE',
  GET_ONE_SUCCESS = '[File] GET_ONE_SUCCESS',
  GET_ONE_ERROR = '[File] GET_ONE_ERROR',
  UPDATE = '[File] UPDATE',
  UPDATE_SUCCESS = '[File] UPDATE_SUCCESS',
  UPDATE_ERROR = '[File] UPDATE_ERROR',
  SELECT = '[File] Select',
  DELETE = '[File] DELETE',
  DELETE_SUCCESS = '[File] DELETE_SUCCESS',
  DELETE_ERROR = '[File] DELETE_ERROR',
  UPSERT = '[File] UPSERT',
  UPSERT_SUCCESS = '[File] UPSERT_SUCCESS',
  UPSERT_ERROR = '[File] UPSERT_ERROR',
  API_DOWNLOAD = '[API File] DOWNLOAD',
  API_DOWNLOAD_SUCCESS = '[API File] DOWNLOAD_SUCCESS',
  API_DOWNLOAD_ERROR = '[API File] DOWNLOAD_ERROR',
  TRIGGER_BROWSER_DOWNLOAD = '[File] TRIGGER_BROWSER_DOWNLOAD',
  TRIGGER_BROWSER_PREVIEW = '[File] TRIGGER_BROWSER_PREVIEW'
}

// Actions for Getting  Files
export class GetFiles implements Action {
  readonly type = FileActionTypes.GET;
  constructor(
    public payload: {
      resource: string;
      resourceId: string;
    }
  ) {}
}

export class GetFilesSuccess implements Action {
  readonly type = FileActionTypes.GET_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: File[]) {}
  constructor(public payload: { files: File[] }) {}
}
export class GetFilesError implements Action {
  readonly type = FileActionTypes.GET_ERROR;
  constructor(public payload: any) {}
}
// Action for Creating File
export class CreateFile implements Action {
  readonly type = FileActionTypes.ADD_ONE;

  constructor(public payload: { file: File }) {}
}
export class CreateFileSuccess implements Action {
  readonly type = FileActionTypes.ADD_ONE_SUCCESS;

  constructor(public payload: { file: File }) {}
}
export class CreateFileError implements Action {
  readonly type = FileActionTypes.ADD_ONE_ERROR;
  constructor(public payload: any) {}
}
export class GetFile implements Action {
  readonly type = FileActionTypes.GET_ONE;
  constructor(public payload: any) {}
}
export class GetFileSuccess implements Action {
  readonly type = FileActionTypes.GET_ONE_SUCCESS;

  constructor(public payload: { file: File }) {}
}

export class GetFileError implements Action {
  readonly type = FileActionTypes.GET_ONE_ERROR;
  constructor(public payload: any) {}
}
export class UpdateFile implements Action {
  readonly type = FileActionTypes.UPDATE;

  constructor(public payload: any) {}
}

export class UpdateFileSuccess implements Action {
  readonly type = FileActionTypes.UPDATE_SUCCESS;

  constructor(public payload: { file: Update<File> }) {}
}

export class UpdateFileError implements Action {
  readonly type = FileActionTypes.UPDATE_ERROR;

  constructor(public payload: any) {}
}

export class UpsertFile implements Action {
  readonly type = FileActionTypes.UPSERT;

  constructor(public payload: { file: File }) {}
}

export class UpsertFileSuccess implements Action {
  readonly type = FileActionTypes.UPSERT_SUCCESS;

  constructor(public payload: { file: File }) {}
}

export class UpsertFileError implements Action {
  readonly type = FileActionTypes.UPSERT_ERROR;

  constructor(public payload: any) {}
}

export class DeleteFile implements Action {
  readonly type = FileActionTypes.DELETE;

  constructor(public payload: any) {}
}
export class DeleteFileSuccess implements Action {
  readonly type = FileActionTypes.DELETE_SUCCESS;

  constructor(public payload: any) {}
}
export class DeleteFileError implements Action {
  readonly type = FileActionTypes.DELETE_ERROR;

  constructor(public payload: File) {}
}

export class ApiDownloadFile implements Action {
  readonly type = FileActionTypes.API_DOWNLOAD;

  constructor(public payload: { file: File; preview: boolean }) {}
}

export class ApiDownloadFileSuccess implements Action {
  readonly type = FileActionTypes.API_DOWNLOAD_SUCCESS;

  constructor(public payload: any) {}
}

export class ApiDownloadFileError implements Action {
  readonly type = FileActionTypes.API_DOWNLOAD_ERROR;

  constructor(public payload: any) {}
}

export class TriggerBrowserDownload implements Action {
  readonly type = FileActionTypes.TRIGGER_BROWSER_DOWNLOAD;

  constructor(public payload: File) {}
}

export class TriggerBrowserPreview implements Action {
  readonly type = FileActionTypes.TRIGGER_BROWSER_PREVIEW;

  constructor(public payload: any) {}
}

export class SelectFile implements Action {
  readonly type = FileActionTypes.SELECT;
  constructor(public payload: { file: Update<File> }) {}
}

export type FileActions =
  | GetFile
  | GetFileSuccess
  | GetFileError
  | UpdateFile
  | UpdateFileSuccess
  | UpdateFileError
  | UpsertFile
  | UpsertFileSuccess
  | UpsertFileError
  | GetFiles
  | GetFilesSuccess
  | GetFilesError
  | CreateFile
  | CreateFileSuccess
  | CreateFileError
  | DeleteFile
  | DeleteFileSuccess
  | DeleteFileError
  | ApiDownloadFile
  | ApiDownloadFileSuccess
  | ApiDownloadFileError
  | TriggerBrowserDownload
  | TriggerBrowserPreview
  | SelectFile;
