import { ToastEffects } from '@app/core/store/effects/toast.effects';
import { FileEffects } from '@app/core/store/effects/file.effects';
import { ContactEffects } from '@app/core/store/effects/contact.effects';
import { EquipmentEffects } from '@app/core/store/effects/equipment.effects';
import { MailNotifyEffects } from '@app/core/store/effects/emailNotify.effects';
import { AvatarEffects } from '@app/core/store/effects/avatar.effects';
import { OrderEffects } from '@app/core/store/effects/order.effects';

export const effects: any[] = [
  ToastEffects,
  FileEffects,
  ContactEffects,
  EquipmentEffects,
  MailNotifyEffects,
  AvatarEffects,
  OrderEffects
];

export * from '@app/core/store/effects/toast.effects';
export * from '@app/core/store/effects/file.effects';
export * from '@app/core/store/effects/contact.effects';
export * from '@app/core/store/effects/equipment.effects';
export * from '@app/core/store/effects/emailNotify.effects';
export * from '@app/core/store/effects/avatar.effects';
export * from '@app/core/store/effects/order.effects';
