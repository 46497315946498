import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/core/store';
import { Logout } from '@app/auth/store/actions/auth.actions';

@Component({
  selector: 'app-user-home',
  template: `
    <div>
      <h3>Welcome Home!</h3>
      <button mat-flat-button color="primary" (click)="logout()">Logout</button>
    </div>
  `,
  styles: []
})
export class UserHomeComponent {
  constructor(private store: Store<fromStore.State>) {}

  logout() {
    this.store.dispatch(new Logout());
  }
}
