import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import * as fromAuthStore from '@app/auth/store';

import { Contact } from '@app/shared/models';

import { ToastHelper } from '@app/core/services/toast.service';

declare var $: any;

@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentUser$: Observable<Contact>;

  constructor(
    private router: Router,
    private _sanitizer: DomSanitizer,
    private store: Store<fromAuthStore.State>,
    private notify: ToastHelper
  ) {}

  ngOnInit() {
    this.currentUser$ = this.store.select(fromAuthStore.selectAuthUser);
  }

  logout() {
    // this.coreStore.dispatch(new fromUserStore.Logout());
  }

  ngOnDestroy() {}
}
