import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FileService } from '@app/core/services';
import { map, tap, takeUntil } from 'rxjs/operators';
import { LifeSubjectDestroyer } from '@app/shared/models';

@Component({
  selector: 'app-preview-dialog',
  template: `
    <div style="max-height: 95vh; overflow: auto">
      <iframe
        *ngIf="file.contentType.includes('application')"
        [src]="localFileSrc"
        frameborder="0"
        scrolling="no"
        style="height: 85vh; width: 100%"
      >
        Your browser does not support inline frames.
      </iframe>

      <img
        *ngIf="file.contentType.includes('image')"
        [src]="localFileSrc"
        style="height: 85vh; width: 100%; object-fit: contain;"
        alt=""
      />
    </div>
  `
})
export class FilePreviewDialogComponent extends LifeSubjectDestroyer {
  localFileSrc;
  constructor(
    public _DomSanitizer: DomSanitizer,
    private _FileService: FileService,
    private dialogRef: MatDialogRef<FilePreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public file: any
  ) {
    super();
    const name = file.name;
    this._FileService.download(file).pipe(
      takeUntil(this.destroyed$),
      tap(f => {
        this.localFileSrc = _DomSanitizer.bypassSecurityTrustResourceUrl(
          this._FileService.getBlobURL({
            ...f,
            name,
            uploadName: file.uploadName
          })._url
        );
      })
    )
    .subscribe();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
