import {
  Component,
  EventEmitter,
  Output,
  Input,
  ElementRef,
  ViewChild
} from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { ContactService } from '@app/core/services/contact.service';
import { tap, takeUntil } from 'rxjs/operators';
import { LifeSubjectDestroyer } from '@app/shared/models/lifeSubjectDestroyer.model';
import { Contact } from '@app/shared/models';

@Component({
  selector: 'app-contact-search',
  templateUrl: 'contact-search.component.html',
  styleUrls: ['contact-search.component.scss']
})
export class ContactSearchComponent extends LifeSubjectDestroyer {
  @ViewChild('search', { static: false })
  search: ElementRef;

  @Input()
  set value(val: string) {
    this._value = val || '';
  }
  _value = '';
  @Input()
  placeholder: string;
  @Input()
  disabled: boolean;
  @Input()
  additionalFilter;
  @Input()
  hideTextAfterSelect: boolean;
  @Input()
  excludeContacts: Contact[];
  @Input()
  canCreateNew: boolean;

  @Output()
  contactSelected = new EventEmitter<Contact>();

  contacts: Contact[];

  showAddBtn = false;

  constructor(
    public _ContactService: ContactService
  ) {
    super();
  }

  displayFn(user) {
    if (user) {
      return user.name;
    }
  }

  onKeyUp(filterName: string) {
    this.showAddBtn = filterName ? true : false;
    this._ContactService
      .getAll({ ...this.additionalFilter, name: filterName })
      .pipe(
        takeUntil(this.destroyed$),
        tap(getContactsResponse => this.contacts = getContactsResponse.contacts.filter((contact: Contact) => {
          let returnValue = true;
          if (this.excludeContacts) {
            this.excludeContacts.forEach(excludeContact => excludeContact._id === contact._id ? returnValue = false : null);
          }
          return returnValue;
        }))
      )
      .subscribe();
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    this.contactSelected.emit(event.option.value);
    this.actionAfterSelectOrCreate();
  }

  onCreateNewContact() {
    if (this.additionalFilter && this.additionalFilter.fatherId) {
      this._ContactService
        .createSub(this.additionalFilter.fatherId, { role: this.additionalFilter.role, name: this.search.nativeElement.value })
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe(contact => {
          this.contactSelected.emit(contact);
        });
    } else {
      this._ContactService
        .create({ role: this.additionalFilter.role, name: this.search.nativeElement.value })
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe(contact => {
          this.contactSelected.emit(contact);
        });
    }
    this.actionAfterSelectOrCreate();
  }

  actionAfterSelectOrCreate() {
    this.search.nativeElement.blur();
    if (this.hideTextAfterSelect) {
      setTimeout(_ => (this.search.nativeElement.focus(), this._value = ''), 100);
    }
  }
}
