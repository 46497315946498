import * as fromToasts from '@app/core/store/actions/toast.actions';
import { Toast } from '@app/core/models/toast.model';
import * as uuid from 'uuid/v4';

export interface ToastsState {
  entities: Toast[];
}

export const initialState: ToastsState = {
  entities: []
};

export function reducer(
  state = initialState,
  action: fromToasts.ToastsAction
): ToastsState {
  switch (action.type) {
    case fromToasts.ToastActionTypes.ADD: {
      return {
        ...state,
        entities: [...state.entities, action.payload]
      };
    }

    case fromToasts.ToastActionTypes.DISMISS: {
      return {
        ...state,
        entities: [
          ...state.entities.filter((toast: Toast) => {
            return toast.id !== action.payload.id;
          })
        ]
      };
    }
  }
  return state;
}

export const getToastEntities = (state: ToastsState) => state.entities;
