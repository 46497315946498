import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contact } from '@app/shared/models/contact.model';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl
} from '@angular/forms';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: 'contact-modal.component.html'
})
export class ContactDialogComponent implements OnInit {
  set contact(value: Contact) {
    if (this.form) {
      this.form.patchValue(value);
    }
    this._contact = value;
  }
  _contact: Contact;
  resTypes = {
    emails: ['Home', 'Work', 'Other'],
    phones: ['Home', 'Work', 'Other', 'Mobile', 'Main', 'Home Fax', 'Work Fax']
  };

  contactRoles = ['Carrier', 'Broker', 'Dispatcher', 'Driver', 'Customer'];

  form: FormGroup;
  filteredUsers;
  isLoading: Boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.contact = data;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', Validators.required],
      role: ['', Validators.required],
      address: this.fb.group({
        addressLine1: [''],
        addressLine2: [''],
        city: [''],
        state: [''],
        zip: ['']
      }),
      phones: this.fb.array([this.createContactRes()]),
      emails: this.fb.array([this.createContactRes()]),
      auth: this.fb.group({
        email: [''],
        password: ['']
      })
    });
    this.form.patchValue(this._contact);
  }

  get name() {
    return this.form.get('name') as FormControl;
  }
  get role() {
    return this.form.get('role') as FormControl;
  }

  get phones() {
    return this.form.get('phones') as FormArray;
  }

  get emails() {
    return this.form.get('emails') as FormArray;
  }

  get address() {
    return this.form.get('address') as FormGroup;
  }

  get auth() {
    return this.form.get('auth') as FormGroup;
  }

  addContactRes(type): void {
    const controls = this.form.get(type) as FormArray;
    controls.push(this.createContactRes());
  }

  removeContactRes(type, index): void {
    const controls = this.form.get(type) as FormArray;
    controls.removeAt(index);
  }

  createContactRes(): FormGroup {
    return this.fb.group({
      // value: ['', Validators.required],
      value: [''],
      // type: ['', Validators.required],
      type: [''],
      notify: false
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      // this.notify.error('Please enter valid form inputs!');
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      return;
    }
    this.form.value.phones = this.form.value.phones.filter(e => e.value);
    this.form.value.emails = this.form.value.emails.filter(e => e.value);
    this.dialogRef.close({ ...this._contact, ...this.form.value });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
