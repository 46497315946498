export interface Note {
  _id?: string;
  content: string;
  creator?: {
    _id: string;
    name: string;
  };
  createdAt?: string;
  followUp?: string;
  assignee?: any[];
  followUpType?: string;
  isCompleted?: string;
  edit?: Boolean;
  visibility?: any[];
}

export function genMockNote(): Note {
  return {
    content: ''
  };
}
