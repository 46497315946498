import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import * as fromCoreStore from '@app/core/store';
import { selectOrderFiles, selectOrderContacts } from '@app/core/store/selectors';
import { takeUntil, tap } from 'rxjs/operators';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MailNotify, LifeSubjectDestroyer } from '@app/shared/models';

@Component({
  selector: 'app-email-send-modal',
  templateUrl: './email-send-modal.component.html',
  styleUrls: ['./email-send-modal.component.scss']
})
export class EmailSendModalComponent extends LifeSubjectDestroyer {
  form: FormGroup;

  orderContacts: any[];
  selectEmailsSettings = {
    singleSelection: false,
    text: 'Select emails',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true
  };

  orderFiles: any[];
  selectFilesSettings = {
    singleSelection: false,
    text: 'File emails',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true
  };

  constructor(
    public dialogRef: MatDialogRef<EmailSendModalComponent>,
    @Inject(MAT_DIALOG_DATA) public emailNotify: MailNotify,
    private fb: FormBuilder,
    private store: Store<fromCoreStore.State>,
  ) {
    super();
    this.store
      .select(selectOrderContacts)
      .pipe(
        takeUntil(this.destroyed$),
        tap(orderContacts =>
          this.orderContacts =
          //  Object.values(orderContacts).flat()
          Object.values(orderContacts)
            .flat()
            .filter(contact => contact)
            .flatMap(contact =>
            contact.emails.map(email =>
              ({
                id: email._id,
                itemName: email.value + ' (' + contact.name + ' ' + contact.role + ')',
                value: email.value
              })
            )
          )
        )
      )
      .subscribe();

    this.store
      .select(selectOrderFiles)
      .pipe(
        takeUntil(this.destroyed$),
        tap(orderFiles => this.orderFiles =
          // orderFiles
          orderFiles.map(file => ({ id: file._id, itemName: file.name, value: file._id }))
          )
      )
      .subscribe();

    this.form = this.fb.group({
      to: new FormControl(this.emailNotify.to, [Validators.required]),
      subject: new FormControl(this.emailNotify.subject, [Validators.required]),
      body: new FormControl(this.emailNotify.body, [Validators.required]),
      attachment: new FormControl(this.emailNotify.attachment, [Validators.required]),
    });
    this.form.patchValue(emailNotify);
  }

  onSubmit() {
    if (this.form.invalid) {
      // this.notify.error('Please enter valid form inputs!');
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      return;
    }
    this.dialogRef.close({ ...this.emailNotify, ...this.form.value });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
