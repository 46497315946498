import {
  Component,
  Input,
  Output,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter
} from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { File } from '@app/shared/models/file.model';
import * as fromStore from '@app/core/store';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-files',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './files.component.html',
  styles: []
})
export class FilesComponent implements OnInit {
  @Input()
  text;
  @Input()
  resource;
  @Input()
  resourceId;
  @Input()
  permissions;

  @Input()
  multiple = false;

  @Output()
  send = new EventEmitter();

  files$: Observable<File[]>;
  hasSelected = false;

  constructor(private store: Store<fromStore.State>) {}

  ngOnInit() {
    this.files$ = this.store
      .select(fromStore.selectFilesByResourceAndResourceId(this.resource, this.resourceId))
      .pipe(
        map(files => {
          if (this.permissions) {
            return files.filter(f => {
              return f.permissions.find(p => this.permissions.includes(p));
            });
          }
          return files;
        })
      );

    this.store.dispatch(
      new fromStore.GetFiles({
        resource: this.resource,
        resourceId: this.resourceId
      })
    );
  }

  changeSelectedFiles(selectedFile: File) {
    this.store.dispatch(new fromStore.UpdateFileSuccess({ file: { id: selectedFile._id, changes: selectedFile } }));
  }

  getInitials(ob) {
    if (ob.creator && ob.creator.name) {
      return (name || '').substr(0, 1).toUpperCase();
    }
    return '';
  }

  download(event) {
    this.store.dispatch(new fromStore.ApiDownloadFile(event));
  }

  uploadSuccess(msg: string) {
    this.store.dispatch(new fromStore.ToastsOk(msg));
    this.store.dispatch(
      new fromStore.GetFiles({
        resource: this.resource,
        resourceId: this.resourceId
      })
    );
  }

  update(event) {
    if (event.push) {
      this.store.dispatch(
        new fromStore.UpdateFile({
          resource: this.resource,
          resourceId: this.resourceId,
          file: event.file
        })
      );
    } else {
      this.store.dispatch(
        new fromStore.SelectFile({
          file: {
            id: event.file._id,
            changes: event.file
          }
        })
      );
    }
  }

  uploadError(msg: string) {
    this.store.dispatch(new fromStore.ToastsError(msg));
  }

  delete(file: File) {
    this.store.dispatch(new fromStore.DeleteFile(file));
  }
}
