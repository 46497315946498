import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Login } from '@app/auth/store/actions/auth.actions';
import { State } from '@app/auth/store/reducers/auth.reducers';
import { selectLoginPagePending, selectLoginPageError } from '@app/auth/store/selectors/login-page.selectors';
import { Authenticate } from '@app/auth/models/authentication.model';

@Component({
  selector: 'app-login-page',
  template: `
    <app-login-form
      [error]="error$ | async"
      [disabled]="pending$ | async"
      (submitted)="onLogin($event)">
    </app-login-form>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 128px 12px 12px 12px;
      }

      app-login-form {
        width: 100%;
        min-width: 250px;
        max-width: 300px;
      }
    `
  ]
})
export class LoginPageComponent implements OnInit {
  error$ = this.store.select(selectLoginPageError);
  pending$ = this.store.select(selectLoginPagePending);

  constructor(private store: Store<State>) {}

  ngOnInit() {}

  onLogin(credentials: Authenticate) {
    this.store.dispatch(new Login(credentials));
  }
}
