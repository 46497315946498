import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, switchMap, mergeMap, catchError } from 'rxjs/operators';

import * as MailNotifyActions from '@app/core/store/actions/emailNotify.actions';
import { MailNotifyService } from '@app/core/services/emailNotify.service';
import { ToastHelper } from '@app/core/services/toast.service';

@Injectable()
export class MailNotifyEffects {
  @Effect()
  getEmailNotifies$: Observable<Action> = this.actions$.pipe(
    ofType<MailNotifyActions.GetEmailNotifies>(MailNotifyActions.MailNotifyActionTypes.GET),
    switchMap(action => {
      return this._MailNotifyService.getAll(action.payload).pipe(
        map(resp => new MailNotifyActions.GetEmailNotifiesSuccess(resp)),
        catchError(error => of(new MailNotifyActions.GetEmailNotifiesError(error)))
      );
    })
    );

  @Effect()
  createMailNotify$: Observable<Action> = this.actions$.pipe(
    ofType<MailNotifyActions.CreateMailNotify>(
      MailNotifyActions.MailNotifyActionTypes.CREATE_CONTACT
    ),
    mergeMap(action => {
      return this._MailNotifyService.create(action.payload.emailNotify).pipe(
        map(emailNotify => {
          this.notify.ok('MailNotify Added Successful!');
          return new MailNotifyActions.CreateMailNotifySuccess({
            emailNotify
          });
        }),
        catchError(error => {
          this.notify.error(error.message);
          return of(new MailNotifyActions.CreateMailNotifyError(error));
        })
      );
    })
    );

  @Effect()
  getOneMailNotify$: Observable<Action> = this.actions$.pipe(
    ofType<MailNotifyActions.GetMailNotify>(
      MailNotifyActions.MailNotifyActionTypes.GET_ONE
    ),
    mergeMap((action: MailNotifyActions.GetMailNotify) => {
      // console.log(action.payload);
      return this._MailNotifyService.get(action.payload._id).pipe(
        map(resp => {
          return new MailNotifyActions.GetMailNotifySuccess({
            emailNotify: resp
          });
        }),
        catchError(error => {
          this.notify.error(error);
          return of(new MailNotifyActions.GetEmailNotifiesError(error));
        })
      );
    })
  );

  @Effect()
  updateMailNotify$: Observable<Action> = this.actions$.pipe(
    ofType<MailNotifyActions.UpdateMailNotify>(
      MailNotifyActions.MailNotifyActionTypes.UPDATE
    ),
    mergeMap(action => {
      return this._MailNotifyService.update(action.payload.emailNotify.changes).pipe(
        map(resp => {
          this.notify.ok('MailNotify update was Successful!');
          return new MailNotifyActions.UpdateMailNotifySuccess({
            emailNotify: { id: resp._id, changes: resp }
          });
        }),
        catchError(error => {
          this.notify.error(error);
          return of(
            new MailNotifyActions.UpdateMailNotifyError(
              action.payload.emailNotify.changes
            )
          );
        })
      );
    })
  );

  @Effect()
  deleteMailNotify$: Observable<Action> = this.actions$.pipe(
    ofType<MailNotifyActions.DeleteMailNotify>(
      MailNotifyActions.MailNotifyActionTypes.DELETE
    ),
    mergeMap(action => {
      return this._MailNotifyService.delete(action.payload).pipe(
        map((data: Response) => {
          this.notify.ok('MailNotify Deleted Successful!');
          return new MailNotifyActions.DeleteMailNotifySuccess(data);
        }),
        catchError(error => {
          this.notify.error(error);
          return of(new MailNotifyActions.DeleteMailNotifyError(error));
        })
      );
    })
  );

  constructor(
    private _MailNotifyService: MailNotifyService,
    private actions$: Actions,
    private notify: ToastHelper
  ) {}
}
