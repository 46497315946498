/// <reference types="@types/googlemaps" />

import { Directive, ElementRef, NgZone, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { GoogleMapService } from '../services/google-map.service';

@Directive({
  selector: '[appGooglePlaceAutoComplete]'
})
export class GooglePlaceAutoCompleteDirective implements OnInit {

  @Input()
  addressObject = {
    streetNumber: '',
    addressLine1: '',
    city: '',
    state: '',
    zip: ''
  };
  @Input()
  bonusClassToInput: string;

  @Output()
  onSave = new EventEmitter<any>();

  public componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    sublocality: 'long_name',
    sublocality_level_1: 'long_name',
    administrative_area_level_1: 'short_name',
    postal_code: 'short_name'
  };

  public address = {
    street_number: '',
    route: '',
    locality: '',
    sublocality: '',
    sublocality_level_1: '',
    administrative_area_level_1: '',
    postal_code: ''
  };

  constructor(
    private searchElementRef: ElementRef,
    private mapsAPILoader: GoogleMapService,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    let autocomplete;
    // load Places Autocomplete
    this.mapsAPILoader.loadAPI.then(() => {
      autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          types: ['geocode']
        }
      );

      autocomplete.setComponentRestrictions({ country: 'us' });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          Object.keys(this.componentForm).map(field => {
            this.address[field] = '';
          });

          if (!place || !place.address_components) {
            return false;
          }

          // Get each component of the address from the place details
          // and fill the corresponding field on the form.
          let compType;
          place.address_components.map(comp => {
            compType = comp.types[0];
            if (this.componentForm[compType]) {
              this.address[compType] = comp[this.componentForm[compType]];
            }
          });
          this.addressObject.streetNumber = this.address.street_number;
          this.addressObject.addressLine1 = this.address.route;
          this.addressObject.state = this.address.administrative_area_level_1;
          this.addressObject.city =
            this.address.locality ||
            this.address.sublocality ||
            this.address.sublocality_level_1;
          this.addressObject.zip = this.address.postal_code;

          // this.onSave.emit(this.addressObject.addressLine1);

          this.onSave.emit(this.addressObject);
          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
        });
      });
    });
  }

  isEmptyObj(obj) {
    obj = obj || {};
    let isEmpty = true;
    Object.keys(obj).forEach(function(prop) {
      isEmpty = isEmpty ? !obj[prop] : isEmpty;
    });
    return isEmpty;
  }

  isValidZip(input) {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(input);
  }

}
