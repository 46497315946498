import { Contact } from "@app/shared/models/contact.model";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import {
  ContactActions,
  ContactActionTypes
} from "@app/core/store/actions/contact.actions";

export interface State extends EntityState<Contact> {
  selectId: string | null;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<Contact> = createEntityAdapter<Contact>({
  selectId: (contact: Contact) => contact._id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectId: null,
  loaded: false,
  loading: false
});

export function reducer(state = initialState, action: ContactActions): State {
  switch (action.type) {
    case ContactActionTypes.GET_SUCCESS: {
      return adapter.addAll(
        action.payload.filter(el => el != null),
        {
          ...state,
          selectId: state.selectId,
          loaded: true,
          loading: false
        }
      );
    }

    case ContactActionTypes.GET_ONE_SUCCESS: {
      return adapter.addOne(action.payload.contact, {
        ...state,
        selectId: state.selectId,
        loaded: true,
        loading: false
      });
    }

    case ContactActionTypes.SELECT: {
      return {
        ...state,
        selectId: action.payload
      };
    }

    // case ContactActionTypes.ADD_ONE: {
    //   // return adapter.addOne(action.payload.contact, state);
    //   // Nothing to change to the store at this point.
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // }

    case ContactActionTypes.CREATE_CONTACT_SUCCESS: {
      return adapter.addOne(action.payload.contact, { ...state, loaded: true });
    }

    case ContactActionTypes.CREATE_SUB_CONTACT_SUCCESS: {
      return adapter.addOne(action.payload.contact, { ...state, loaded: true });
    }

    case ContactActionTypes.UPSERT_SUCCESS: {
      return adapter.upsertOne(action.payload.contact, {
        ...state,
        selectId: action.payload.contact._id
      });
    }

    case ContactActionTypes.UPSERT_MANY: {
      return adapter.upsertMany(action.payload, state);
    }

    // case ContactActionTypes.UPSERT_MANY: {
    // return adapter.upsertMany(action.payload.contacts, state);
    // }

    case ContactActionTypes.UPDATE: {
      return adapter.updateOne(action.payload.contact, state);
    }

    case ContactActionTypes.UPDATE_SUCCESS: {
      return adapter.updateOne(action.payload.contact, state);
    }

    case ContactActionTypes.GET_BY_IDS_SUCCESS: {
      return adapter.upsertMany(action.payload.contacts, state);
    }

    case ContactActionTypes.DELETE: {
      return adapter.removeOne(action.payload, state);
    }

    case ContactActionTypes.DELETE_MANY: {
      return adapter.removeMany(action.payload, state);
    }

    // case ContactActionTypes.DELETE_MANY: {
    //   return adapter.removeMany(action.payload.ids, state);
    // }

    // case ContactActionTypes.DELETE_MANY: {
    //   return adapter.removeAll({ ...state, selectedContactId: null });
    // }

    default: {
      return state;
    }
  }
}
export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getSelectedId = (state: State) => state.selectId;
export const {
  // select the array of Contact ids
  selectIds: selectContactIds,
  // select the dictionary of Contact entities
  selectEntities: selectContactEntities,
  // select the array of contacts
  selectAll: selectAllContact,
  // select the total Contact count
  selectTotal: selectContactTotal
} = adapter.getSelectors();
