import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotFoundPageComponent } from '@app/core/containers/not-found-page';
import { AuthGuardService } from '@app/auth/guards/auth-guard.service';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: 'auth',
          loadChildren: '@app/auth/auth.module#AuthModule',
        },
        {
          path: 'loads',
          loadChildren: '@app/loadboard/loadboard.module#LoadboardModule',
          canActivate: [AuthGuardService],
          data: { action: 'list_order' }
        },
        {
          path: 'contacts',
          loadChildren: '@app/contacts/contacts.module#ContactsModule',
          canActivate: [AuthGuardService],
        },
        { path: '', redirectTo: '/loads', pathMatch: 'full' },
        { path: '**', component: NotFoundPageComponent }
      ],
      {
        useHash: true
      }
    )
  ],
  exports: [RouterModule],
  providers: [AuthGuardService]
})
export class AppRoutingModule {}
