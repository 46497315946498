import { MetaReducer, createFeatureSelector } from '@ngrx/store';

import * as fromRouterReducer from '@app/core/store/reducers/router.reducer';
import * as fromToast from '@app/core/store/reducers/toast.reducer';
import * as fromFile from '@app/core/store/reducers/file.reducers';
import * as fromContact from '@app/core/store/reducers/contact.reducers';
import * as fromPagination from '@app/core/store/reducers/pagination.reducers';
import * as fromEquipment from '@app/core/store/reducers/equipment.reducers';
import * as fromMailNotify from '@app/core/store/reducers/emailNotify.reducers';
import * as fromAvatar from '@app/core/store/reducers/avatar.reducers';
import * as fromOrder from '@app/core/store/reducers/order.reducers';

import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import * as fromRouter from '@ngrx/router-store';

import { environment } from 'environments/environment';
/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */

export interface State {
  routerReducer: fromRouter.RouterReducerState<
    fromRouterReducer.RouterStateUrl
  >;
  toasts: fromToast.ToastsState;
  files: fromFile.State;
  contacts: fromContact.State;
  pagination: fromPagination.State;
  equipments: fromEquipment.State;
  emailNotify: fromMailNotify.State;
  avatars: fromAvatar.State;
  orders: fromOrder.State;
}

export const reducers = {
  routerReducer: fromRouter.routerReducer,
  toasts: fromToast.reducer,
  files: fromFile.reducer,
  contacts: fromContact.reducer,
  pagination: fromPagination.reducer,
  equipments: fromEquipment.reducer,
  emailNotify: fromMailNotify.reducer,
  avatars: fromAvatar.reducer,
  orders: fromOrder.reducer
};

export const metaReducers: MetaReducer<any>[] = !environment.production
  ? []
  : [];

export class CustomSerializer
  implements
    fromRouter.RouterStateSerializer<fromRouterReducer.RouterStateUrl> {
  serialize(
    routerState: RouterStateSnapshot
  ): fromRouterReducer.RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const { params } = state;

    return { url, queryParams, params };
  }
}

export const selectCoreState = createFeatureSelector<State>('core');
